<template>
  <div class="container py-5">
    <div class="row align-items-center">
      <div class="col">
        <form method="post" enctype="multipart/form-data">
          <div class="container">
            <p class="header-text my-5 text-center">STAJYER KAYIT FORMU</p>

            <div class="d-grid gap-3">
              <div>
                <input class="form-control ps-4" :class="{ 'is-invalid': $v.name.$errors.length }" placeholder="Ad"
                  v-model="state.name" />
                <invalid-message :errors="$v.name.$errors" />
              </div>
              <div>
                <input class="form-control ps-4" :class="{ 'is-invalid': $v.lastname.$errors.length }"
                  placeholder="Soyad" v-model="state.lastname" />
                <invalid-message :errors="$v.lastname.$errors" />
              </div>
              <div>
                <input type="email" class="form-control ps-4" :class="{ 'is-invalid': $v.email.$errors.length }"
                  placeholder="Email" v-model="state.email" />
                <invalid-message :errors="$v.email.$errors" />
              </div>
              <div>
                <input type="tel" v-mask="'+90 (1##) ###-####'" class="form-control ps-4"
                  :class="{ 'is-invalid': $v.phone.$errors.length }" placeholder="Telefon Numarası"
                  v-model="state.phone" />
                <invalid-message :errors="$v.phone.$errors" />
              </div>
              <div>
                <input type="password" class="form-control ps-4" :class="{ 'is-invalid': $v.password.$errors.length }"
                  placeholder="Parola" v-model="state.password" />
                <invalid-message :errors="$v.password.$errors" />
              </div>
              <div>
                <input type="password" class="form-control ps-4"
                  :class="{ 'is-invalid': $v.passwordAgain.$errors.length }" placeholder="Parola Tekrar"
                  v-model="state.passwordAgain" />
                <invalid-message :errors="$v.passwordAgain.$errors" />
              </div>

              <v-date-picker v-model="state.birthday" mode="date" :max-date="new Date()"
                :attributes="[{ key: 'today', highlight: true, dates: new Date(), }]">
                <template v-slot="{ inputValue, inputEvents }">
                  <input class="form-control ps-4" :class="{ 'is-invalid': $v.birthday.$errors.length }"
                    :value="inputValue" v-on="inputEvents" placeholder="Doğum Tarihi" v-mask="'##.##.####'"
                    autocomplete="off" />
                  <invalid-message :errors="$v.birthday.$errors" />
                </template>
              </v-date-picker>

              <FileInput tabindex="-1" key="studentDoc" v-model="state.studentDoc" :maxFileSize="3" :maxFiles="1"
                :accept="['.jpg', '.jpeg', '.png', '.bmp', '.pdf']" title="Öğrenci Belgesi"
                description="Barkodlu e-devlet çıktısı yükleyiniz!" :image="require('@/assets/file_add.svg')"
                :class="{ 'success': state.studentDoc != null, 'is-invalid': $v.studentDoc.$errors.length }">
              </FileInput>

              <div>
                <input class="form-control ps-4" :class="{ 'is-invalid': $v.schoolName.$errors.length }"
                  placeholder="Okul" v-model="state.schoolName" />
                <invalid-message :errors="$v.schoolName.$errors" />
              </div>
              <div>
                <input class="form-control ps-4" :class="{ 'is-invalid': $v.className.$errors.length }"
                  placeholder="Sınıf" maxlength="8" v-model="state.className" />
                <invalid-message :errors="$v.className.$errors" />
              </div>
              <div>
                <input type="number" class="form-control ps-4" :class="{ 'is-invalid': $v.schoolScore.$errors.length }"
                  placeholder="AGO" max="4" min="0" v-model.number="state.schoolScore" />
                <invalid-message :errors="$v.schoolScore.$errors" />
              </div>
            </div>
            <div class="text-center mt-5">
              <h6 class="header-sub mb-0">Resim Ekle</h6>
              <FileInput key="profilePicture" tabindex="-1" v-model="state.profilePicture" :maxFileSize="2"
                :maxFiles="1" :imageMode="true" :accept="['.jpg', '.jpeg', '.png', '.bmp']"
                :image="require('@/assets/user.svg')" class="border-0 d-inline-flex"
                :class="{ 'success': state.profilePicture != null, 'is-invalid': $v.profilePicture.$errors.length }">
              </FileInput>
              <invalid-message :errors="$v.profilePicture.$errors" />
            </div>
            <div class="form-check mt-5">
              <input class="form-check-input" type="checkbox" v-model="state.readed" id="term-check"
                :class="{ 'is-invalid': $v.readed.$errors.length }" />
              <label class="form-check-label user-term" for="term-check">
                <a href="/kayit-uyelik-sozlesmesi" target="_blank">Üyelik sözleşmesini</a> okudum ve onaylıyorum.
              </label>
            </div>
            <div class="d-grid gap-2 my-5 px-5">
              <button class="btn btn-primary" type="button" @click="save" :disabled="sending || !isOnline">
                <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true" v-if="sending"></span>
                <span class="visually-hidden">Kaydınız Yapılıyor...</span>
                <span v-if="!sending" class="mx-2">{{ isOnline ? 'Kayıt Ol' : 'İnternet Bağlantınız Yok' }}</span>
              </button>
              <router-link :to="{ name: 'kayit' }" class="btn btn-secondary">
                İptal
              </router-link>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<script setup>
import md5 from 'md5';
import FileInput from "@/components/customs/FileInput.vue";
import { useConAware } from "@/utils/connectionAware";
import { useVuelidate } from '@vuelidate/core';
import { required, email, minLength, helpers, maxValue, minValue, maxLength } from '@vuelidate/validators';
import { ref, inject, nextTick, watch } from 'vue';
import { useRouter } from 'vue-router';


const router = useRouter();
const { isOnline } = useConAware();
const axios = inject('axios');
const toast = inject('toast');

const sending = ref(false);
const state = ref({
  name: '',
  lastname: '',
  email: '',
  phone: '',
  password: '',
  passwordAgain: '',
  schoolName: '',
  className: '',
  schoolScore: null,
  birthday: null,
  readed: false,
  studentDoc: null,
  profilePicture: null,
});


const mustBeSame =
  (value) => {
    return value === state.value.password
  };

const rules = {
  name: { required: helpers.withMessage('Adınızı bilmemiz gerekiyor.', required) },
  lastname: { required: helpers.withMessage('Soyadınızı bilmemiz gerekiyor.', required) },
  email: { required: helpers.withMessage('Lütfen email adresinizi yazınız.', required), email: helpers.withMessage('Lütfen geçerli bir email adresi yazınız.', email) },
  phone: { required: helpers.withMessage('Lütfen telefon numaranızı yazınız.', required) },
  password: { required: helpers.withMessage('Lütfen kullanmak istediğiniz şifrenizi yazınız.', required), minLength: helpers.withMessage('Parolanız en az 6 karakter olmalıdır.', minLength(6)), },
  passwordAgain: {
    required: helpers.withMessage('Lütfen kullanmak istediğiniz şifrenizi yazınız.', required), minLength: helpers.withMessage('Parolanız en az 6 karakter olmalıdır.', minLength(6)), mustBeSame: helpers.withMessage('Parolanız yukarıdaki ile uyuşmuyor.', mustBeSame)
  },
  birthday: { required: helpers.withMessage('Lütfen doğum tarihinizi yazınız.', required) },
  profilePicture: { required: helpers.withMessage("Lütfen bir profil resmi yükleyiniz.", (v) => v != null) },
  studentDoc: { required: helpers.withMessage("Lütfen öğrenci belgenizi yükleyiniz.", (v) => v != null) },
  schoolName: { required: helpers.withMessage('Lütfen okulunuzun adını yazınız.', required) },
  className: { required: helpers.withMessage('Lütfen sınıfınızı yazınız.', required), maxLength: helpers.withMessage('En fazla 8 karakter yazabilirsiniz.', maxLength(8)) },
  schoolScore: { required: helpers.withMessage('Lütfen ağırlıklı genel ortalamanızı yazınız.', required), maxValue: helpers.withMessage('Ortalamanız en fazla 4 olabilir.', maxValue(4)), minValue: helpers.withMessage('Ortalamanız en az 0 olabilir.', minValue(0)) },
  readed: { required: helpers.withMessage("Lütfen üyelik sözleşmesini okuyup onaylayınız.", (v) => v === true) },
};

const $v = useVuelidate(rules, state);

watch(() => ({ schoolScore: state.value.schoolScore }), async (value) => {
  if (value.schoolScore > 4) {
    await nextTick();
    state.value.schoolScore = 4;
  } 
});

function focusHasErrors() {
  const elem = document.querySelector(".is-invalid");
  if (elem) {
    elem.focus();
  }
}

async function save() {
  $v.value.$touch();
  const errors = $v.value.$errors;
  await nextTick();

  if (errors.length) {
    focusHasErrors();
  } else {
    saveData();
  }
}

async function saveData() {
  try {
    const formData = new FormData();
    formData.append("name", state.value.name);
    formData.append("lastname", state.value.lastname);
    formData.append("email", state.value.email);
    formData.append("phone", state.value.phone);
    formData.append("password", md5(state.value.password));
    formData.append("birthday", state.value.birthday?.toISOString());
    formData.append("schoolName", state.value.schoolName);
    formData.append("className", state.value.className);
    formData.append("schoolScore", state.value.schoolScore);

    formData.append("profilePicture", state.value.profilePicture);
    formData.append("studentDoc", state.value.studentDoc);


    if (isOnline.value) {
      sending.value = true;
      const res = await axios.post('/api/register/student', formData, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      });
      toast.success(res.data.message);

      localStorage.setItem("acctoken", res.data.token);
      localStorage.setItem("name", res.data.adsoyad);
      localStorage.setItem("type", res.data.utype);

      router.regen();
      router.replace({ path: '/' });
    }

  } catch (error) {
    if (error.etype === 'response') {
      toast.error(error.response.data.message, 5000);
    } else {
      toast.error(error.message, 5000);
    }
  } finally {
    sending.value = false;
  }

}



</script>


<style lang="scss" scoped>
.add-btn {
  background: url("@/assets/add.svg") center center;
  background-repeat: no-repeat;
  height: 30px;
  width: 30px;
  border: 0;
}

.user-term {
  color: rgba(0, 0, 0, 0.4);

  a {
    color: rgba(0, 0, 0, 0.4);

    &:hover {
      color: #6c63ff;
    }
  }
}

.header-text {
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 150%;
  color: rgba(0, 0, 0, 0.5);
}

.header-sub {
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
  color: rgba(0, 0, 0, 0.4);
}

.accordion-item,
.accordion-button {
  background-color: #eceff9;
  border: 0;
}

.accordion-button {
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  color: #6c63ff;

  &:not(.collapsed) {
    color: #6c63ff;
    background-color: #eceff9;
    box-shadow: unset;
  }

  &:focus {
    z-index: 3;
    border-color: unset;
    outline: 0;
    box-shadow: unset;
  }
}

@media (min-width: 0) and (max-width: 576px) {
  .container {
    width: 100%;
  }
}

@media (min-width: 576px) and (max-width: 1200px) {
  .container {
    width: 70%;
  }
}

@media (min-width: 1200px) {
  .container {
    width: 50%;
  }
}
</style>