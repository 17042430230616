<template>
    <div
        class="card date-wrapper py-2 py-xl-3 py-lg-3 px-2 px-xl-4 px-lg-4 mx-auto mx-xl-0 mx-lg-0 d-inline-block"
    >
        <div class="d-inline-flex d-xl-flex d-lg-flex justify-content-between gap-2">
            <button
                v-for="date in ranges"
                type="button"
                class="date-item"
                :class="{ active: date.toDate().toLocaleDateString() == activeDate?.toLocaleDateString() }"
                @click.stop="activeDate = date.toDate()"
                :key="date"
            >
                <b>{{ $filters.formatDate(date, 'DD') }}</b>
                {{ $filters.formatDate(date, 'MMM') }}
            </button>
        </div>
    </div>
    <v-date-picker
        v-model="activeDate"
        mode="date" 
        class="date-item active m-2 m-xl-3 m-lg-3 p-0"
    >
        <template #default="{ inputValue, inputEvents }">
            <input :value="inputValue" type="hidden" />
            <div v-on="inputEvents" class="d-flex" style="padding:20px">
                <i class="gi-filter" style="font-size: 24px;"></i>
            </div>
        </template>
    </v-date-picker>
</template>
<script setup>
import { ref, inject, computed, watch } from 'vue';
const moment = inject('moment');

const props = defineProps({
    modelValue: Date
});
const emit = defineEmits(['update:modelValue']);

const activeDate = ref(props.modelValue);

const ranges = computed(() => {
    let fromDate = moment().startOf('day');
    let toDate = moment().startOf('day').add(6, 'days');
    let diff = toDate.diff(fromDate, 'days');
    let range = [];
    for (let i = 0; i < diff; i++) {
        range.push(moment(fromDate).add(i, 'days'));
    }
    return range;
});

watch(activeDate, (n, o) => { 
    if (n?.getDate() != o?.getDate()) emit('update:modelValue', activeDate.value);
});

</script>


<style type="scss" scoped>
.date-wrapper {
    -ms-overflow-style: none;
    scrollbar-width: none;
    overflow-y: scroll;
}

.date-wrapper::-webkit-scrollbar {
    display: none;
}

.date-item {
    color: #27272e;
    width: 65px;
    padding: 20px 0;
    border-radius: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    font-weight: 300;
    border: 0;
    background-color: white;
    cursor: pointer;
}

.date-item.active {
    background-color: #6b64fc;
    color: white;
}

@media (min-width: 0) and (max-width: 576px) {
    .date-item {
        color: #27272e;
        font-size: 14px;
        width: 45px;
        padding: 10px 0;
        border-radius: 10px;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        font-weight: 300;
        border: 0;
        background-color: white;
    }
}
</style>