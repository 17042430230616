<template>
    <router-view v-slot="{ Component }">
        <transition name="slide-fade">
            <component :is="Component" />
        </transition>
    </router-view>
    <ToastMessage ref="toast"></ToastMessage>
</template>
<script setup>
import ToastMessage from "@/components/customs/ToastMessage.vue";
import { ref, provide, onMounted } from 'vue';
const toast = ref();


onMounted(() => {
    provide('toast', toast.value);
});
</script>