import { ref, onMounted, onUnmounted } from "vue";

// by convention, composable function names start with "use"
export function useConAware() {
  const isOnline = ref(true);

  // a composable can update its managed state over time.
  function update() {
    const nav = window.navigator;
    isOnline.value = nav != null ? nav.onLine : true;
  }

  onMounted(() => {
    window.addEventListener("online", update);
    window.addEventListener("offline", update);
  });
  onUnmounted(() => {
    window.removeEventListener("online", update);
    window.removeEventListener("offline", update);
  });

  return { isOnline };
}
