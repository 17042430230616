<template>
    <div class="container-fluid">
        <div class="container mt-4 d-flex justify-content-center">
            <DateSelector v-model="activeDate" />
        </div>
        <div
            class="container d-flex justify-content-start justify-content-xl-center justify-content-lg-center mt-3 px-3">
            <div class="form-check form-switch">
                <input v-model="showAll" class="form-check-input" type="checkbox" role="switch"
                    style="height: 1.5em;    width: 2.5em;" id="flexSwitchCheckDefault" />
                <label class="form-check-label ms-2" for="flexSwitchCheckDefault" style="vertical-align:sub;">{{
                        showAll
                            ? 'Tümünü Göster' : 'Sadece Benimkiler'
                }}</label>
            </div>
        </div>

        <div class="row">
            <template v-if="loading">
                <div class="col col-xl-4 col-lg-6 col-md-6 p-2 p-xl-3 p-lg-3" style="flex-basis: auto;" v-for="n in 20"
                    :key="'ps-' + n">
                    <SessionCard :skeleton="true" />
                </div>
            </template>

            <div v-if="!loading && !sessions.length" class="my-3 py-5 text-center">
                <img v-if="type == 'psychologist'" class="img-fluid mb-4" src="@/assets/create-session.svg"
                    style="height: 350px;" />
                <img v-else class="img-fluid mb-4" src="@/assets/no-session.svg" style="height: 350px;" />
                <p class="py-3">{{ type != 'psychologist' ? $filters.messages('MY_NO_SESSION') :
                        $filters.messages('NO_SESSION')
                }}</p>
                <router-link v-if="type == 'psychologist'" :to="{ name: 'yeniseans' }" class="btn-detail py-2 px-5">
                    Seans Oluştur</router-link>
            </div>

            <div class="col col-xl-4 col-lg-6 col-md-6 p-2 p-xl-3 p-lg-3" style="flex-basis: auto;"
                v-for="session in sessions" :key="session.sid">
                <SessionCard :item="session" />
            </div>
        </div>
        <div v-if="sessions.length && !noMore" class="d-flex justify-content-center p-5">
            <button class="btn btn-light text-purple" @click="loadMore">
                <span class="spinner-border spinner-border-sm mx-5" role="status" aria-hidden="true"
                    v-if="loadingMore"></span>
                <span class="visually-hidden">Bilgiler getiriliyor...</span>
                <span v-if="!loadingMore" class="mx-2">{{ !loadingMore ? 'Daha Fazla Göster' : '' }}</span>
            </button>
        </div>
    </div>
</template>


<script setup>
import _ from 'lodash';
import { useActiveUser } from "@/utils/activeUser";
import DateSelector from '@/components/customs/DateSelector.vue';
import SessionCard from '@/components/cards/SessionCard.vue';
import { ref, onMounted, inject, watch } from 'vue';


const toast = inject('toast');
const axios = inject('axios');
const moment = inject('moment');
const { type } = useActiveUser();
const activeDate = ref(null);
const showAll = ref(type == 'psychologist' ? false : true);
const loading = ref(true);
const loadingMore = ref(false);
const noMore = ref(false);
const sessions = ref([]);
let page = 1;



watch(activeDate, () => {
    load();
});

watch(showAll, () => {
    load();
});

async function load() {
    try {
        loadingMore.value = false;
        sessions.value = [];
        page = 1;
        loading.value = true;

        let date = activeDate.value ? moment(activeDate.value).format('YYYY-MM-DD') : null;
        const res = await axios.post('/api/sessions', { page, all: showAll.value, date });
        sessions.value = res.data.sessions;
    } catch (error) {
        if (error.etype === 'response') {
            toast.error(error.response.data.message);
        } else {
            toast.error(error.message);
        }
    } finally {
        loading.value = false;
    }
}

async function loadMore() {
    try {
        loadingMore.value = true;
        let date = activeDate.value ? moment(activeDate.value).format('YYYY-MM-DD') : null;
        const res = await axios.post('/api/sessions', { page: page + 1, all: showAll.value, date });
        if (res.data.sessions.length) {
            sessions.value = _.concat(sessions.value, res.data.sessions);
            page = page + 1;
        } else {
            noMore.value = true;
        }

    } catch (error) {
        if (error.etype === 'response') {
            toast.error(error.response.data.message);
        } else {
            toast.error(error.message);
        }
    } finally {
        loadingMore.value = false;
    }
}

onMounted(() => {
    load();
});
</script>

 