<template>
    <div class="container-fluid">
        <h1 class="page-heading mx-0">Psikologlar</h1>

        <div class="row justify-content-center my-4">
            <div class="col col-xl-4 col-lg-6 col-md card py-3 px-4 mx-2 mx-xl-0 mx-lg-0">
                <form @submit.prevent="load">
                    <div class="d-flex align-items-center" style="position: relative;">
                        <input
                            v-model="search"
                            type="text"
                            class="form-control light"
                            placeholder="Psikolog ara"
                        />
                        <button
                            class="btn btn-primary btn-sm no-shadow float-end px-4 py-2"
                            style="position:absolute; right:5px; "
                            type="submit"
                        >Ara</button>
                    </div>
                </form>
            </div>
        </div>

        <div class="row">
            <template v-if="loading">
                <div
                    class="col col-xl-3 col-lg-6 col-md-6 p-2 p-xl-3 p-lg-3"
                    style="flex-basis: auto;"
                    v-for="n in 20"
                    :key="'ps-' + n"
                >
                    <PsychologistCard :skeleton="true" />
                </div>
            </template>

            <div v-if="!loading && !users.length" class="my-3 py-5 text-center">
                <img class="img-fluid mb-4" src="@/assets/login.svg" style="height: 350px;" />
                <p v-if="search">Aradığınız kritere uygun bir psikolog bulunmamaktadır.</p>
                <p v-else>Henüz kayıtlı bir psikolog bulunmamaktadır.</p>
            </div>

            <div
                class="col col-xl-3 col-lg-6 col-md-6 p-2 p-xl-3 p-lg-3"
                style="flex-basis: auto;"
                v-for="user in users"
                :key="user.uid"
            >
                <PsychologistCard :item="user" />
            </div>
        </div>
        <div v-if="users.length && !noMore" class="d-flex justify-content-center p-5">
            <button class="btn btn-light text-purple" @click="loadMore">
                <span
                    class="spinner-border spinner-border-sm mx-5"
                    role="status"
                    aria-hidden="true"
                    v-if="loadingMore"
                ></span>
                <span class="visually-hidden">Bilgiler getiriliyor...</span>
                <span v-if="!loadingMore" class="mx-2">{{ !loadingMore ? 'Daha Fazla Göster' : '' }}</span>
            </button>
        </div>
    </div>
</template>


<script setup>
import _ from 'lodash';
import PsychologistCard from '@/components/cards/PsychologistCard.vue';
import { ref, onMounted, inject } from 'vue';
const axios = inject('axios');


const errorMessage = ref('');
const loading = ref(true);
const loadingMore = ref(false);
const noMore = ref(false);
const search = ref(null);
const users = ref([]);
let page = 1;


async function load() {
    try {
        loadingMore.value = false;
        loading.value = true;
        users.value = [];
        page = 1;
        const res = await axios.post('/api/users/psychologists', { page, search: search.value });
        users.value = res.data.users;
    } catch (error) {
        if (error.etype === 'response') {
            errorMessage.value = error.response.data.message;
        } else {
            errorMessage.value = error.message;
        }
    } finally {
        loading.value = false;
    }
}

async function loadMore() {
    try {
        loadingMore.value = true;
        const res = await axios.post('/api/users/psychologists', { page: page + 1, search: search.value });
        if (res.data.users.length) {
            users.value = _.concat(users.value, res.data.users);
            page = page + 1;
        } else {
            noMore.value = true;
        }

    } catch (error) {
        if (error.etype === 'response') {
            errorMessage.value = error.response.data.message;
        } else {
            errorMessage.value = error.message;
        }
    } finally {
        loadingMore.value = false;
    }
}

onMounted(() => {
    load();
})
</script>


 