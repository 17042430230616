import "bootstrap/dist/css/bootstrap.min.css";
import bootstrap from "bootstrap/dist/js/bootstrap.bundle";
import "v-calendar/dist/style.css";
import "./assets/gurupicons.scss";
import "./assets/custom.scss";

import { VueMaskPlugin } from "v-mask";
import VCalendar from "v-calendar";
import axios from "axios";
import VueAxios from "vue-axios";
import moment from "moment";
moment.locale("tr");

axios.defaults.baseURL = window.origin;

axios.interceptors.request.use(
  function (config) {
    let token = localStorage.getItem("acctoken");
    if (token) {
      config.headers.Authorization = "bearer " + token;
    }
    return config;
  },
  function (error) {
    return Promise.reject(error);
  }
);

axios.interceptors.response.use(
  function (response) {
    return response;
  },
  function (error) {
    if (error.response) {
      const status = error.response.status;
      if (status >= 500) {
        error.etype = "server";
        error.message = "Sunucu bir hata gönderdi.";
      } else if (status == 403 || status == 404) {
        error.etype = "403404";
        error.message =
          "Erişilmek istenen sayfa bulunamadı ya da böyle bir sayfa yok.";
      } else {
        error.etype = "response";
      }
    } else if (error.request) {
      error.etype = "network";
      error.message = "İnternet bağlantısında bir sorun oluştu.";
    } else {
      error.etype = "error";
    }
    return Promise.reject(error);
  }
);

import { createApp } from "vue";
import App from "./App.vue";
import * as routeConfig from "./appRouter.js";
import InvalidInputMessage from "@/components/customs/InvalidInputMessage.vue";
import MESSAGES from "@/utils/messagesConst";

const app = createApp(App);
app.use(routeConfig.router);
app.filter = (name, fn) => {
  app.config.globalProperties[name] = fn;
};

app.directive = (name, fn) => {
  if (name == "mask") {
    app._context.directives[name] = {
      beforeMount: fn.bind,
      updated: fn.componentUpdated,
      unmounted: fn.unbind,
    };
  } else {
    app._context.directives[name] = fn;
  }
};

app.config.globalProperties.$filters = {
  formatDate(date, format) {
    if (date) {
      return format
        ? moment(date).format(format)
        : moment(date).format("DD.MM.YYYY hh:mm:ss");
    } else return "";
  },
  messages(key) {
    return MESSAGES[key];
  },
};

app.use(VueMaskPlugin, {
  placeholders: {
    A: /[a-zıİğüşöç]/i,
    N: /[a-zıİğüşöç0-9]/i,
    1: /[1-9]/,
  },
});

app.use(VueAxios, axios);
app.provide("axios", app.config.globalProperties.axios);
app.provide("bootstrap", bootstrap);
app.provide("moment", moment);
app.component("invalid-message", InvalidInputMessage);
app.use(VCalendar, {});
app.directive("tooltip", {
  mounted(el) {
    new bootstrap.Tooltip(el, {
      boundary: document.body,
    });
  },
  unmounted(el) {
    var tooltip = bootstrap.Tooltip.getInstance(el);
    tooltip?.dispose();
  },
});
app.mount("#app");
