<template>
    <div class="position-fixed top-0 start-50 translate-middle-x p-3" style="z-index: 50000">
        <div
            id="liveToast"
            class="toast text-white border-0"
            :class="{ show: showed, 'bg-danger': type === 'error', 'bg-success': type === 'success', 'bg-info': type === 'info' }"
            role="alert"
            aria-live="assertive"
            aria-atomic="true"
        >
            <div class="d-flex">
                <div class="toast-body">{{ message }}</div>
                <button
                    type="button"
                    class="btn-close btn-close-white me-2 m-auto"
                    data-bs-dismiss="toast"
                    aria-label="Close"
                ></button>
            </div>
        </div>
    </div>
</template>
<script  >
import { ref } from 'vue';

export default {
    setup() {
        const showed = ref(false);
        const message = ref('');
        const type = ref('');
        let delayed = 2000;

        const error = (msg, duration = 2000) => {
            delayed = duration;
            message.value = msg;
            type.value = 'error';
            showed.value = true; delay();
        }

        const success = (msg, duration = 2000) => {
            delayed = duration;
            message.value = msg;
            type.value = 'success';
            showed.value = true; delay();
        }

        const info = (msg, duration = 2000) => {
            delayed = duration;
            message.value = msg;
            type.value = 'info';
            showed.value = true;
            delay();
        }

        const delay = () => setTimeout(() => {
            showed.value = false;
        }, delayed);

        return { type, showed, message, error, success, info };
    }
};
</script>