<template>
    <div class="container">
        <h1 class="page-heading mx-0">Seans Oluştur</h1>
        <div class="card">
            <div class="card-body">
                <div class="d-grid gap-3">
                    <div>
                        <label class="light mb-3">Seans Başlığı</label>
                        <input v-model="state.title" class="form-control light ps-4"
                            :class="{ 'is-invalid': $v.title.$errors.length }" maxlength="40" title="40 karakter" />
                        <invalid-message :errors="$v.title.$errors" />
                    </div>

                    <div>
                        <div class="row">
                            <v-date-picker class="col-12 col-xl col-lg col-md-6" mode="datetime"
                                v-model="state.startDate" :available-dates="{ start: new Date(), span: 60 }"
                                :minute-increment="10" :masks="{
                                    input: 'YYYY-MM-DD HH:mm',
                                }" :attributes="[{ key: 'today', dot: true, dates: new Date(), }]" is24hr>
                                <template v-slot="{ inputValue, inputEvents }">
                                    <label class="light mb-3">Tarih</label>
                                    <input class="form-control light ps-4"
                                        :class="{ 'is-invalid': $v.startDate.$errors.length }" :value="inputValue"
                                        v-on="inputEvents" autocomplete="off" />
                                    <invalid-message :errors="$v.startDate.$errors" />
                                </template>
                            </v-date-picker>
                            <div class="col-12 col-xl col-lg col-md-6">
                                <label class="light mb-3 mt-3 mt-xl-0 mt-lg-0 mt-md-0">Süre</label>
                                <input class="form-control light ps-4" readonly value="2.5  Saat" />
                            </div>
                        </div>
                    </div>
                    <div>
                        <div class="row">
                            <div class="col-12 col-xl col-lg col-md-6">
                                <label class="light mb-3">Nickname Konsept</label>
                                <select v-model="state.conceptId" class="form-select light ps-4"
                                    :class="{ 'is-invalid': $v.conceptId.$errors.length }">
                                    <option :value="concept.ncid" v-for="concept in concepts" :key="concept.ncid">{{
                                        concept.title
                                    }}</option>
                                </select>
                                <invalid-message :errors="$v.conceptId.$errors" />
                            </div>
                            <div class="col-12 col-xl col-lg col-md-6">
                                <label class="light mb-3 mt-3 mt-xl-0 mt-lg-0 mt-md-0">Kategori</label>
                                <select v-model="state.categoryId" class="form-select light ps-4"
                                    :class="{ 'is-invalid': $v.categoryId.$errors.length }">
                                    <option :value="cat.cid" v-for="cat in categories" :key="cat.cid">{{ cat.title }}
                                    </option>
                                </select>
                                <invalid-message :errors="$v.categoryId.$errors" />
                            </div>
                        </div>
                    </div>

                    <div>
                        <div class="row">
                            <div class="col-12 col-xl col-lg col-md">
                                <label class="light mb-3">Katılımcı Sayısı</label>
                                <input v-model="state.maxClient" type="number" min="3" max="7"
                                    class="form-control light ps-4"
                                    :class="{ 'is-invalid': $v.maxClient.$errors.length }" />
                                <div class="invalid-feedback" v-for="error of $v.maxClient.$errors" :key="error.$uid">{{
                                    error.$message
                                }}</div>
                            </div>
                            <div class="col-12 col-xl col-lg col-md">
                                <label class="light mb-3 mt-3 mt-xl-0 mt-lg-0 mt-md-0">Stajyer Sayısı</label>
                                <input v-model="state.maxStudent" type="number" min="1" max="2"
                                    class="form-control light ps-4"
                                    :class="{ 'is-invalid': $v.maxStudent.$errors.length }" />
                                <div class="invalid-feedback" v-for="error of $v.maxStudent.$errors" :key="error.$uid">
                                    {{ error.$message }}</div>
                            </div>
                            <div class="col-12 col-xl col-lg col-md">
                                <label class="light mb-3 mt-3 mt-xl-0 mt-lg-0 mt-md-0">Bilet Sayısı</label>
                                <input v-model="state.ticketCount" type="number" min="2" max="10"
                                    class="form-control light ps-4"
                                    :class="{ 'is-invalid': $v.ticketCount.$errors.length }" />
                                <div class="invalid-feedback" v-for="error of $v.ticketCount.$errors" :key="error.$uid">
                                    {{ error.$message }}</div>
                            </div>
                        </div>
                    </div>
                    <div>
                        <label class="light mb-3 mt-3 mt-xl-0 mt-lg-0 mt-md-0">Gurup Tipi</label>
                        <div class="form-check form-switch">
                            <input class="form-check-input" type="checkbox" v-model="state.access" role="switch"
                                id="flexSwitchCheckDefault" />
                            <label class="form-check-label" for="flexSwitchCheckDefault">{{
                                state.access ? 'Açık Gurup'
                                    : 'Kapalı Gurup'
                            }}</label>
                        </div>
                    </div>
                </div>
                <div class="mt-5">
                    <button class="btn btn-primary no-shadow" type="button" @click="save"
                        :disabled="sending || !isOnline">
                        <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"
                            v-if="sending"></span>
                        <span class="visually-hidden">Seans Kaydı Yapılıyor...</span>
                        <span v-if="!sending" class="mx-2">{{
                            isOnline ? 'Oluştur' : 'İnternet Bağlantınız Yok'
                        }}</span>
                    </button>
                </div>
            </div>
        </div>
    </div>
</template>

<script setup>
import { useConAware } from "@/utils/connectionAware";
import { useVuelidate } from '@vuelidate/core';
import { required, helpers, maxValue, minValue, maxLength } from '@vuelidate/validators';
import { ref, inject, nextTick, watch, onMounted } from 'vue';
import { useRouter } from 'vue-router';

const { isOnline } = useConAware();
const axios = inject('axios');
const toast = inject('toast');
const moment = inject('moment');
const router = useRouter();

const sending = ref(false);
const categories = ref([]);
const concepts = ref([]);
const state = ref({
    title: '',
    ticketCount: 2,
    maxClient: 7,
    maxStudent: 2,
    startDate: null,
    access: true,
    conceptId: null,
    categoryId: null,
});


watch(() => ({ ticketCount: state.value.ticketCount, maxClient: state.value.maxClient, maxStudent: state.value.maxStudent }), async (value) => {
    if (value.ticketCount > 10) {
        await nextTick();
        state.value.ticketCount = 10;
    }

    if (value.ticketCount < 2 && value.ticketCount) {
        await nextTick();
        state.value.ticketCount = 2;
    }

    if (value.maxClient > 7) {
        await nextTick();
        state.value.maxClient = 7;
    }

    if (value.maxClient < 3 && value.maxClient) {
        await nextTick();
        state.value.maxClient = 3;
    }

    if (value.maxStudent > 2) {
        await nextTick();
        state.value.maxStudent = 2;
    }

    if (value.maxStudent < 1 && value.maxStudent) {
        await nextTick();
        state.value.maxStudent = 1;
    }
});


const rules = {
    title: { required: helpers.withMessage('Lütfen seans için bir başlık yazınız.', required), maxLength: helpers.withMessage('En fazla 40 karakter yazabilirsiniz.', maxLength(40)) },
    startDate: { required: helpers.withMessage('Lütfen seans için bir tarih seçiniz.', required) },
    ticketCount: { required: helpers.withMessage('Lütfen seans için bilet sayısı yazınız.', required), maxValue: helpers.withMessage('Bilet sayısı en fazla 10 olabilir.', maxValue(10)), minValue: helpers.withMessage('Bilet sayısı en az 2 olabilir.', minValue(2)) },
    maxClient: { required: helpers.withMessage('Lütfen seans için katılımcı sayısı yazınız.', required), maxValue: helpers.withMessage('Katılımcı sayısı en fazla 7 olabilir.', maxValue(7)), minValue: helpers.withMessage('Katılımcı sayısı en az 3 olabilir.', minValue(3)) },
    maxStudent: { required: helpers.withMessage('Lütfen seans için stajyer sayısı yazınız.', required), maxValue: helpers.withMessage('Stajyer sayısı en fazla 2 olabilir.', maxValue(2)), minValue: helpers.withMessage('Stajyer sayısı en az 1 olabilir.', minValue(1)) },
    conceptId: { required: helpers.withMessage('Lütfen seans için bir nickname konsepti seçiniz.', required) },
    categoryId: { required: helpers.withMessage('Lütfen seans için bir kategori seçiniz.', required) },
};

const $v = useVuelidate(rules, state);


async function loadAdditional() {
    try {
        categories.value = [];
        concepts.value = [];
        const res = await axios.get('/api/categories/withNicknameConcepts');
        categories.value = res.data.categories;
        concepts.value = res.data.concepts;
    } catch (error) {
        if (error.etype === 'response') {
            toast.error(error.response.data.message, 5000);
        } else {
            toast.error(error.message, 5000);
        }
    }
}

function focusHasErrors() {
    const elem = document.querySelector(".is-invalid");
    if (elem) {
        elem.focus();
    }
}

async function save() {
    $v.value.$touch();
    const errors = $v.value.$errors;
    await nextTick();

    if (errors.length) {
        focusHasErrors();
    } else {
        saveData();
    }
}

async function saveData() {
    try {

        if (isOnline.value) {
            sending.value = true;
            const res = await axios.post('/api/sessions/create', {
                title: state.value.title,
                ticketCount: state.value.ticketCount,
                maxClient: state.value.maxClient,
                maxStudent: state.value.maxStudent,
                startDate: moment(state.value.startDate).format('YYYY-MM-DDTHH:mm'),
                conceptId: state.value.conceptId,
                categoryId: state.value.categoryId,
                access: state.value.access ? 'public' : 'private'

            });
            toast.success(res.data.message, 5000);

            router.push({ path: '/' });
        }

    } catch (error) {
        if (error.etype === 'response') {
            toast.error(error.response.data.message, 5000);
        } else {
            toast.error(error.message, 5000);
        }
    } finally {
        sending.value = false;
    }

}


onMounted(() => {
    loadAdditional();
});
</script>