const DEFAULT_TITLE = "Gurupapp";

import { createRouter, createWebHistory } from "vue-router";
import { useActiveUser } from "@/utils/activeUser";

import NotFound from "@/components/NotFound.vue";
import Home from "@/components/HomePage.vue";
import Login from "@/components/LoginPage.vue";
import ForgotPassword from "@/components/ForgotPassword.vue";
import RenewPassword from "@/components/RenewPassword.vue";
import Register from "@/components/register/RegisterPage.vue";
import RegPsychologistPage from "@/components/register/RegPsychologistPage.vue";
import RegStudentPage from "@/components/register/RegStudentPage.vue";
import RegUserPage from "@/components/register/RegUserPage.vue";
import NewSession from "@/components/psychologist/NewSession.vue";
import PsychologistPage from "@/components/psychologist/PsychologistPage.vue";
import CategoryPage from "@/components/category/CategoryPage.vue";
import CategorySessionsPage from "@/components/category/CategorySessionsPage.vue";
import SessionsPage from "@/components/sessions/SessionsPage.vue";
import SessionDetailPage from "@/components/SessionDetailPage.vue";

const routes = [
  {
    path: "/",
    name: "home",
    component: Home,
  },
  { path: "/giris", name: "giris", component: Login },
  { path: "/unuttum", name: "unuttum", component: ForgotPassword },
  { path: "/yenile/:hash", name: "yenile", component: RenewPassword },
  { path: "/kayit", name: "kayit", component: Register },
  {
    path: "/kayit/psikolog",
    name: "kayit-psikolog",
    component: RegPsychologistPage,
  },
  { path: "/kayit/stajyer", name: "kayit-stajyer", component: RegStudentPage },
  { path: "/kayit/kullanici", name: "kayit-kullanici", component: RegUserPage },
  {
    name: "canli",
    path: "/canli/:id",
    meta: { auth: true },
    component: () => import("@/components/SessionStartedPage.vue"),
  },
  {
    name: "kayit-uyelik-sozlesmesi",
    path: "/kayit-uyelik-sozlesmesi",
    meta: { auth: false },
    component: () => import("@/components/TextPage.vue"),
  },
  {
    name: "kayit-uyelik-sozlesmesi-psikolog",
    path: "/kayit-uyelik-sozlesmesi-psikolog",
    meta: { auth: false },
    component: () => import("@/components/TextPage.vue"),
  },
  { path: "/:pathMatch(.*)*", name: "NotFound", component: NotFound },
];

const router = createRouter({
  history: createWebHistory(),
  routes: routes,
  linkActiveClass: "active",
  linkExactActiveClass: "active",
});

router.regen = () => {
  router.removeRoute("home");

  const { type } = useActiveUser();

  const subRoutes = [
    {
      name: "",
      path: "",
      component: SessionsPage,
      meta: { auth: true, title: "Anasayfa" },
    },
    {
      name: "psikolog",
      path: "/psikolog/:id",
      meta: { auth: true },
      component: () =>
        import("@/components/psychologist/PsychologistProfile.vue"),
    },
    {
      name: "psikolog-seans",
      path: "/psikolog/:id/seans",
      meta: { auth: true },
      component: () =>
        import("@/components/psychologist/PsychologistSessions.vue"),
    },
    {
      name: "profil",
      path: "/profil",
      meta: { auth: true, title: "Profil" },
      component: () => {
        if (type == "psychologist") {
          return import("@/components/psychologist/PsychologistProfile.vue");
        } else {
          return import("@/components/user/UserProfile.vue");
        }
      },
    },
    {
      name: "seanslarim",
      path: "/seanslarim",
      meta: { auth: true, title: "Seanslarım" },
      component: () => {
        if (type == "psychologist") {
          return import("@/components/psychologist/PsychologistSessions.vue");
        } else {
          return import("@/components/user/UserSessions.vue");
        }
      },
    },
    {
      name: "psikologlar",
      path: "/psikologlar",
      meta: { auth: true, title: "Psikologlar" },
      component: PsychologistPage,
    },
    {
      name: "kategoriler",
      path: "/kategoriler",
      meta: { auth: true, title: "Kategoriler" },
      component: CategoryPage,
    },
    {
      name: "kategori-seans",
      path: "/kategoriler/:id",
      component: CategorySessionsPage,
      meta: { auth: true },
    },
    {
      name: "seans",
      path: "/seans/:id",
      meta: { auth: true },
      component: SessionDetailPage,
    },
    {
      name: "pay-success",
      path: "/odeme-basarili",
      meta: { auth: true },
      component: () => import("@/components/PaySuccess.vue"),
    },
    {
      name: "pay-fail",
      path: "/odeme-hata",
      meta: { auth: true },
      component: () => import("@/components/PayFail.vue"),
    },
    {
      name: "kvkk",
      path: "/kvkk",
      meta: { auth: true },
      component: () => import("@/components/TextPage.vue"),
    },
    {
      name: "acikriza",
      path: "/acikriza",
      meta: { auth: true },
      component: () => import("@/components/TextPage.vue"),
    },
    {
      name: "gizlilik-sozlesmesi",
      path: "/gizlilik-sozlesmesi",
      meta: { auth: true },
      component: () => import("@/components/TextPage.vue"),
    },
    {
      name: "uyelik-sozlesmesi",
      path: "/uyelik-sozlesmesi",
      meta: { auth: true },
      component: () => import("@/components/TextPage.vue"),
    },
    {
      name: "uyelik-sozlesmesi-psikolog",
      path: "/uyelik-sozlesmesi-psikolog",
      meta: { auth: true },
      component: () => import("@/components/TextPage.vue"),
    },
    {
      name: "mesafeli-satis",
      path: "/mesafeli-satis",
      meta: { auth: true },
      component: () => import("@/components/TextPage.vue"),
    },
  ];

  if (type == "psychologist") {
    subRoutes.push({
      name: "yeniseans",
      path: "/yeniseans",
      component: NewSession,
      meta: { auth: true, title: "Yeni Seans Oluştur" },
    });
  }

  router.addRoute({
    path: "/",
    name: "home",
    component: Home,
    meta: { auth: true },
    children: subRoutes,
  });
};

router.beforeEach(async (to) => {
  document.querySelector(".tooltip")?.remove();
  var r = document.querySelector(":root");
  r?.style?.setProperty("--bg-color", "#ECEFF9");

  const { isLoggedin } = useActiveUser();

  document.title = to.meta.title || DEFAULT_TITLE;

  if (to.meta.auth && !isLoggedin) {
    return { name: "giris" };
  }
});

router.regen();
export { router };
