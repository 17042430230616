<template>
    <div class="container-fluid header">
        <nav class="navbar navbar-expand-lg navbar-light p-0">
            <div class="container-fluid">
                <router-link to="/" class="navbar-brand">
                    <img src="@/assets/logo.svg" alt="Gurup" width="105" height="80" />
                </router-link>

                <button class="navbar-toggler" type="button" @click="mobileMenuShow = !mobileMenuShow">
                    <span class="navbar-toggler-icon"></span>
                </button>

                <div class="collapse navbar-collapse" :class="{ show: mobileMenuShow }" id="navbarSupportedContent">
                    <ul class="navbar-nav main-navbar mx-auto mb-2 mb-lg-0 gap-1 gap-xl-5 gap-lg-5">
                        <li class="nav-item">
                            <router-link @click="mobileMenuShow = false" to="/"
                                class="nav-link d-flex flex-row flex-xl-column flex-lg-column align-items-center">
                                <i class="gi-home mb-0 mb-xl-1 mb-lg-1 me-3 me-xl-0 me-lg-0"></i>
                                Anasayfa
                            </router-link>
                        </li>
                        <li v-if="type != 'psychologist'" class="nav-item">
                            <router-link @click="mobileMenuShow = false" to="/psikologlar"
                                class="nav-link d-flex flex-row flex-xl-column flex-lg-column align-items-center">
                                <i class="gi-group mb-0 mb-xl-1 mb-lg-1 me-3 me-xl-0 me-lg-0"></i>
                                Psikologlar
                            </router-link>
                        </li>
                        <li v-if="type != 'psychologist'" class="nav-item">
                            <router-link @click="mobileMenuShow = false" to="/kategoriler"
                                class="nav-link d-flex flex-row flex-xl-column flex-lg-column align-items-center">
                                <i class="gi-category mb-0 mb-xl-1 mb-lg-1 me-3 me-xl-0 me-lg-0"></i>
                                Kategoriler
                            </router-link>
                        </li>
                        <li v-if="type == 'psychologist'" class="nav-item">
                            <router-link @click="mobileMenuShow = false" to="/yeniseans"
                                class="nav-link d-flex flex-row flex-xl-column flex-lg-column align-items-center">
                                <i class="gi-add mb-0 mb-xl-1 mb-lg-1 me-3 me-xl-0 me-lg-0"></i>
                                Seans Oluştur
                            </router-link>
                        </li>
                        <li class="nav-item">
                            <router-link @click="mobileMenuShow = false" to="/profil"
                                class="nav-link d-flex flex-row flex-xl-column flex-lg-column align-items-center">
                                <i class="gi-person mb-0 mb-xl-1 mb-lg-1 me-3 me-xl-0 me-lg-0"></i>
                                Profil
                            </router-link>
                        </li>
                    </ul>
                    <ul class="navbar-nav mb-2 mb-lg-0 mx-3 mt-3 mt-xl-0 mt-lg-0 flex-row">
                        <li class="nav-item me-3">
                            <a class="nav-link d-flex d-xl-block d-lg-block btn btn-link" href="#helpModal"
                                data-bs-toggle="modal" data-bs-target="#helpModal" data-bs-placement="bottom"
                                title="Destek" v-tooltip>
                                <i class="gi-headphones me-3 me-xl-0 me-lg-0"></i>
                                <span class="d-xl-none d-lg-none">Destek</span>
                            </a>
                        </li>

                        <li class="nav-item ms-auto">
                            <button type="button" @click="logout"
                                class="nav-link d-flex d-xl-block d-lg-block btn btn-link" v-tooltip
                                data-bs-placement="bottom" title="Çıkış Yap">
                                <i class="gi-logout me-3 me-xl-0 me-lg-0"></i>
                                <span class="d-xl-none d-lg-none">Çıkış Yap</span>
                            </button>
                        </li>
                    </ul>
                </div>
            </div>
        </nav>
        <router-view v-slot="{ Component }">
            <transition name="slide-fade">
                <component :is="Component" :key="$route.fullPath" />
            </transition>
        </router-view>

        <footer style="padding:50px 0;">
            <div class="container-fluid" style="border-top:1px solid #E2E8F0;">
                <div class="d-flex flex-column flex-xl-row flex-lg-row align-items-center pt-5">
                    <span style="font-size:12px">
                        Copyright © 2022
                    </span>

                    <div
                        class="mx-auto d-flex flex-column flex-xl-row flex-lg-row gap-2 gap-xl-3 gap-lg-3 align-items-center my-3 my-xl-0 my-lg-0">
                        <router-link to="/uyelik-sozlesmesi">Üyelik Sözleşmesi</router-link>
                        <router-link to="/gizlilik-sozlesmesi">Gizlilik Sözleşmesi</router-link>
                        <router-link to="/acikriza">Açık Rıza Beyanı</router-link>
                        <router-link to="/kvkk">KVKK</router-link>
                    </div>

                    <div class="me-0 me-xl-3 me-lg-3 d-flex align-items-center" style="font-size:12px">
                        <span>Made with</span>
                        <i class="gi-heart text-danger ms-2" style="font-size: 16px;"></i>
                    </div>
                </div>
            </div>
        </footer>

        <Teleport to="body">
            <div class="modal fade" id="helpModal" tabindex="-1">
                <div class="modal-dialog modal-dialog-centered">
                    <div class="modal-content">
                        <div class="text-end">
                            <button type="button" class="btn-close m-3" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div class="modal-body px-4">
                            <h4 class="modal-title text-center">Size Nasıl Yardımcı Olabiliriz?</h4>
                            <div class="d-grid gap-3">
                                <div>
                                    <h5 class="modal-sub-title">Konu</h5>
                                    <input v-model="subject" class="form-control light" />
                                </div>
                                <div>
                                    <h5 class="modal-sub-title">Mesajınız</h5>
                                    <textarea class="form-control light" rows="3" v-model="comment"></textarea>
                                </div>

                                <button class="btn btn-primary no-shadow" type="button" @click="sendComment"
                                    :disabled="commentSending || !isOnline">
                                    <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"
                                        v-if="commentSending"></span>
                                    <span class="visually-hidden">Bildiriminiz gönderiliyor...</span>
                                    <span v-if="!commentSending" class="mx-2">{{
                                            isOnline ? 'Gönder' : 'İnternet Bağlantınız Yok'
                                    }}</span>
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Teleport>

    </div>
</template>

<script setup>
import { useConAware } from "@/utils/connectionAware";
import { useActiveUser } from '@/utils/activeUser';
import { ref, inject } from 'vue';
import { useRouter } from 'vue-router';


const { isOnline } = useConAware();
const { type } = useActiveUser();
const router = useRouter();
const toast = inject('toast');
const axios = inject('axios');
const bootstrap = inject('bootstrap');

const mobileMenuShow = ref(false);
const subject = ref('');
const comment = ref('');
const commentSending = ref(false);

function logout() {
    mobileMenuShow.value = false;
    localStorage.clear();
    router.replace({ name: 'giris' });
}

async function sendComment() {
    try {
        let modal = document.getElementById("helpModal");

        commentSending.value = true;

        const res = await axios.post('/api/help/add', { title: subject.value, message: comment.value });

        toast.success(res.data.message, 5000);

        bootstrap.Modal.getOrCreateInstance(modal).hide();
    } catch (error) {
        if (error.etype === 'response') {
            toast.error(error.response.data.message, 5000);
        } else {
            toast.error(error.message, 5000);
        }
    } finally {
        commentSending.value = false;
    }

}

</script>

<style type="scss" scoped>
footer a {
    font-size: 12px;
}

.modal-title {
    font-weight: 700;
    font-size: 24px;
    line-height: 62px;
    color: #170F49;
}

.modal-sub-title {
    font-weight: 700;
    font-size: 14px;
    line-height: 20px;
    color: #170F49;
}

@media (min-width: 768px) {
    .header {
        width: 90%;
    }
}

@media (min-width: 0) and (max-width: 576px) {
    .navbar .main-navbar .nav-item .nav-link i {
        background-color: transparent;
    }
}
</style>


 