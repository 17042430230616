<template>
    <div class="container-fluid">
        <h1 class="page-heading mx-0">
            <div v-if="loading" class="skeleton-line h25 w-25"></div>
            {{ state.session?.title ?? '' }}
        </h1>
        <div class="row">
            <div class="col-12 col-xl-3 col-lg-12">
                <div class="card">
                    <div class="card-body">
                        <div class="d-flex align-items-center">
                            <h5 class="card-title mt-3 mx-3">Seans Hakkında</h5>
                            <span class="ms-auto d-flex align-items-center">
                                <i v-if="state.session?.access == 'private'" class="gi-lock" style="font-size: 20px;"
                                    v-tooltip title="Kapalı Gurup"></i>
                                <i v-if="state.session?.process == 'start'" class="gi-live text-primary ms-2"
                                    style="font-size: 24px;" v-tooltip title="Seans Başladı"></i>
                            </span>
                        </div>
                        <h6 class="session-category mx-3">{{ state.session?.category ?? '' }}</h6>
                        <div class="session-title m-3">
                            <img :src="state.session?.imageUrl ?? require('@/assets/user.svg')"
                                class="rounded-circle me-3 max-h-48" />
                            {{ state.session?.psychologist ?? '' }}
                        </div>
                        <h6 class="session-category mx-3">{{
                                $filters.formatDate(state.session?.startDate, 'DD MMMM YYYY - HH: mm')
                        }}</h6>
                        <div class="d-flex justify-content-around align-items-center mt-4 mb-3 stats-info">
                            <span class="d-flex align-items-center">
                                <a href="#suModal" data-bs-toggle="modal" data-bs-target="#suModal">
                                    <i class="gi-persons me-3"></i>
                                    {{ state.session?.activeClient ?? '' }} / {{ state.session?.maxClient ?? '' }}
                                </a>
                            </span>
                            <span class="d-flex align-items-center">
                                <a href="#suModal" data-bs-toggle="modal" data-bs-target="#suModal">
                                    <i class="gi-student me-3"></i>
                                    {{ state.session?.activeStudent ?? '' }} / {{ state.session?.maxStudent ?? '' }}
                                </a>
                            </span>
                            <span class="d-flex align-items-center">
                                <i class="gi-coins me-3"></i>
                                {{ state.session?.ticketCount ? (state.session?.ticketCount*100)+ ' TL': '' }}
                            </span>
                        </div>
                        <div v-if="hasState" class="text-center mt-5 px-4">
                            <button v-if="state.session.mysession || type != 'psychologist'" @click="takeAction"
                                type="button" :disabled="!statusInfo.status || sending || !isOnline"
                                class="btn btn-primary btn-sm no-shadow px-5 w-100">
                                <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"
                                    v-if="sending"></span>
                                <span class="visually-hidden">İşlem Yapılıyor...</span>
                                <span v-if="!sending" class="mx-2">
                                    {{ isOnline ? statusInfo.msg : 'İnternet bağlantınız yok' }}</span>
                            </button>
                            <button
                                v-if="type == 'psychologist' && state.session.mysession && state.session.process != 'cancel' && state.session.process != 'start' && state.session.process != 'end'"
                                @click="cancelSession" type="button" :disabled="sending || !isOnline"
                                class="btn btn-secondary btn-sm no-shadow px-5 mt-2 w-100">
                                <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"
                                    v-if="sending"></span>
                                <span class="visually-hidden">İşlem Yapılıyor...</span>
                                <span v-if="!sending" class="mx-2">
                                    {{ isOnline ? 'Seansı iptal et' : 'İnternet bağlantınız yok' }}</span>
                            </button>
                            <button v-if="canCreateSequel" data-bs-toggle="modal" data-bs-target="#sequelModal"
                                type="button" :disabled="!isOnline"
                                class="btn btn-primary btn-sm no-shadow px-5 mt-2 w-100">
                                <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"
                                    v-if="sending"></span>
                                <span class="visually-hidden">İşlem Yapılıyor...</span>
                                <span v-if="!sending" class="mx-2">
                                    {{ isOnline ? 'Devam Seansı Oluştur' : 'İnternet bağlantınız yok' }}</span>
                            </button>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col py-3 py-xl-0 py-lg-4">
                <div class="card">
                    <div class="card-body">
                        <h5 class="card-title my-3 mx-3">Kimler Katılmalı</h5>
                        <p class="detail mx-3">
                            <span v-if="loading" class="d-block skeleton-line h15 w-75"></span>
                            <span v-if="loading" class="d-block skeleton-line h15 w-50"></span>
                            <span v-if="loading" class="d-block skeleton-line h15 w-50"></span>

                            <span v-if="loading" class="d-block skeleton-line h15 w-100 mt-3"></span>
                            <span v-if="loading" class="d-block skeleton-line h15 w-50"></span>
                            <span v-if="loading" class="d-block skeleton-line h15 w-75"></span>
                            <span v-html="state.session?.description ?? ''"></span>
                        </p>

                        <div v-if="!hasState && errorMessage" class="alert alert-danger my-3" role="alert">
                            <h4 class="alert-heading">Hata!</h4>
                            <p>{{ errorMessage }}</p>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-12 col-xl-4 col-lg-12">
                <div class="card">
                    <div class="card-body">
                        <h5 class="card-title my-3 mx-3">Seans Kuralları</h5>
                        <ul>
                            <li>
                                Terapilere sessiz ve ışıklı ortamlarda katılım sağlanmalıdır.
                            </li>
                            <li>
                                Terapi esnasında bulunucağınız odada tek olmanız ve terapi süresince rahatsız
                                edilmemeniz
                                önemlidir.
                            </li>
                            <li>
                                Terapi esnasında kullanılacak cihazların pil seviyesinin yeterli olduğundan veya güç
                                kaynağına bağlı olunduğundan emin olunmalıdır.
                            </li>
                            <li>
                                Terapilere hızlı ve stabil internet bağlantısının olduğu bir ortamda katılım
                                sağlanmalıdır.
                            </li>
                            <li>
                                Kulaklık ve mikrofon ile katılmak veriminizi artırabilir.
                            </li>
                            <li>
                                Görüntülü veya sesli katılım sağlayabilirsiniz.
                            </li>
                            <li>
                                Terapi sürecinde psikoloğunuzun yönlendirmeleri dikkate alınmalıdır.
                            </li>
                            <li>
                                Terapi süresince karşılıklı saygıya dikkat edilmelidir.
                            </li>
                            <li>
                                Terapi esnasında tek ilgi odağın terapi olması, dikkat dağıtıcı unsurların olmamasına
                                dikkat edilmelidir.
                            </li>
                            <li>
                                Seansın verimliliği için seans süresince terapiden ayrılmamanız gereklidir.
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>

        <Teleport to="body">
            <div class="modal fade" id="conceptModal" tabindex="-1">
                <div class="modal-dialog modal-dialog-centered">
                    <div class="modal-content">
                        <div class="text-end">
                            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div class="modal-body">
                            <h5 class="modal-title text-center my-3">Nickname Seçiniz</h5>

                            <div class="d-grid gap-3">
                                <button @click="register(concept.ncdid)" type="button" v-for="(concept, i) in concepts"
                                    :key="'con-' + i"
                                    class="btn btn-outline-primary rounded-pill text-start p-3 ps-4 border-2"
                                    data-bs-dismiss="modal">{{ concept.name }}</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Teleport>

        <Teleport to="body" v-if="type == 'psychologist'">
            <div class="modal fade" id="suModal" tabindex="-1">
                <div class="modal-dialog modal-lg modal-dialog-centered">
                    <div class="modal-content">
                        <div class="text-end">
                            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div class="modal-body">
                            <h5 class="modal-title text-center my-3">Katılımcılar</h5>

                            <div class="d-grid gap-3">
                                <div class="accordion accordion-flush" id="userAccordion">
                                    <div class="accordion-item" v-for="(user, i) in users" :key="'con-' + i">
                                        <h2 class="accordion-header" :id="'flush-heading' + i">
                                            <button class="accordion-button collapsed"
                                                :class="user.utype == '2' ? 'text-danger' : ''" type="button"
                                                data-bs-toggle="collapse" :data-bs-target="'#flush-collapse' + i"
                                                aria-expanded="false" :aria-controls="'flush-collapse' + i">
                                                {{ user.namesurname }}
                                            </button>
                                        </h2>
                                        <div :id="'flush-collapse' + i" class="accordion-collapse collapse"
                                            :aria-labelledby="'flush-heading' + i" data-bs-parent="#userAccordion">
                                            <div class="accordion-body">
                                                <h5 class="card-title mb-3">Doğum Tarihi</h5>
                                                <p class="detail">{{ $filters.formatDate(user.birthday, 'DD MMMM YYYY')
                                                }}</p>
                                                <h5 class="card-title mb-3">Meslek</h5>
                                                <p class="detail">{{ user.job }}</p>
                                                <h5 class="card-title mb-3">Biyografi</h5>
                                                <p class="detail">{{ user.bio }}</p>
                                                <h5 v-if="user.trouble" class="card-title mb-3">Şikayeti</h5>
                                                <p v-if="user.trouble" class="detail">{{ user.trouble }}</p>
                                                <h5 v-if="user.schoolName" class="card-title mb-3">Okul</h5>
                                                <p v-if="user.schoolName" class="detail">{{ user.schoolName }} - Sınıf :
                                                    {{ user.className }} - AGO : {{ user.schoolScore }}</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <button class="btn btn-secondary no-shadow" type="button" data-bs-dismiss="modal">
                                    Kapat
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Teleport>

        <Teleport to="body" v-if="canCreateSequel">
            <div class="modal fade" id="sequelModal" tabindex="-1" data-bs-backdrop="static">
                <div class="modal-dialog modal-dialog-centered">
                    <div class="modal-content">
                        <div class="text-end">
                            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div class="modal-body">
                            <h5 class="modal-title text-center my-3">Devam Seansı İçin Tarih Seçin</h5>

                            <div class="d-grid gap-3">
                                <v-date-picker mode="datetime" v-model="sequelDate"
                                    :available-dates="{ start: new Date(), span: 60 }" :minute-increment="10" :masks="{
                                        input: 'YYYY-MM-DD HH:mm',
                                    }" :attributes="[{ key: 'today', dot: true, dates: new Date(), }]" is24hr>
                                    <template v-slot="{ inputValue, inputEvents }">
                                        <label class="light mb-3">Tarih</label>
                                        <input class="form-control light ps-4"
                                            :class="{ 'is-invalid': $v.sequelDate.$errors.length }" :value="inputValue"
                                            v-on="inputEvents" autocomplete="off" />
                                        <invalid-message :errors="$v.sequelDate.$errors" />
                                    </template>
                                </v-date-picker>
                                <div class="d-flex gap-3 justify-content-center mt-5">
                                    <button class="btn btn-primary no-shadow" type="button" @click="sequelSave"
                                        :disabled="sequelSending || !isOnline">
                                        <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"
                                            v-if="sequelSending"></span>
                                        <span class="visually-hidden">Seans Kaydı Yapılıyor...</span>
                                        <span v-if="!sequelSending" class="mx-2">{{
                                                isOnline ? 'Oluştur' : 'İnternet Bağlantınız Yok'
                                        }}</span>
                                    </button>
                                    <button class="btn btn-secondary no-shadow" type="button" data-bs-dismiss="modal">
                                        İptal
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Teleport>
    </div>
</template>

<script setup>
import { useConAware } from "@/utils/connectionAware";
import { useActiveUser } from "@/utils/activeUser";
import { ref, onMounted, computed, inject, nextTick } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import { useVuelidate } from '@vuelidate/core';
import { required, helpers } from '@vuelidate/validators';

const bootstrap = inject('bootstrap');
const axios = inject('axios');
const toast = inject('toast');
const moment = inject('moment');
const { type } = useActiveUser();
const { isOnline } = useConAware();
const router = useRouter();

const currentRoute = computed(() => {
    return useRoute()
});

const now = ref(moment());
const sequelDate = ref(null);
const sequelSending = ref(false);
const errorMessage = ref('');
const sending = ref(false);
const loading = ref(true);
const state = ref({});
const hasState = computed(() => {
    return state.value.session != undefined;
});

const concepts = computed(() => {
    return state.value.concepts ?? [];
});

const users = computed(() => {
    return state.value.users ?? [];
});

const canCreateSequel = computed(() => {
    const _hs = hasState.value;

    if (!_hs) return false;
    const _s = state.value.session;

    return type == 'psychologist'
        && _s.mysession
        && _s.access == 'private'
        && (_s.process == 'recordsEnd' || _s.process == 'end');
});

const statusInfo = computed(() => {

    const session = state.value.session;

    const process = session.process;
    const startDate = moment(session.startDate);
    const lastMin = startDate.diff(now.value, 'minutes');


    if (session.subscribed) {
        if (type == 'psychologist') {
            if (process == 'wait') {
                if (lastMin < -10) {
                    return { msg: 'Seansın Süresi Geçmiş', status: false, type: 'wait' };
                } else if (lastMin > 10) {
                    return { msg: 'Başlatmak İçin Son ' + lastMin + ' Dakika', status: false, type: 'wait' };
                } else {
                    return { msg: 'Seansı Başlat', status: true, type: 'start' };
                }
            } else if (process == 'start') {
                if (lastMin < -150) {
                    return { msg: 'Seans Bitmiş', status: false, type: 'end' };
                } else {
                    return { msg: 'Seansa Gir', status: true, type: 'enter' };
                }
            } else if (process == 'recordsEnd') {
                if (lastMin < 10 && lastMin > -10) {
                    return { msg: 'Seansı Başlat', status: true, type: 'start' };
                } else {
                    if (lastMin < 0) {
                        return { msg: 'Seansın Süresi Geçmiş', status: false, type: 'end' };
                    } else {
                        return { msg: 'Başlatmak İçin Son ' + lastMin + ' Dakika', status: false, type: 'wait' };
                    }
                }
            } else {
                return { msg: 'Seans Sonlanmıştır', status: false, type: 'end' };
            }
        } else {
            if (process == 'wait') {
                return { msg: 'Seanstan Çık', status: true, type: 'exit' };
            } else if (process == 'start') {
                if (lastMin < -150) {
                    return { msg: 'Seans Sonlanmıştır', status: false, type: 'end' };
                } else if (session.kicked) {
                    return { msg: 'Seanstan Atıldınız', status: false, type: 'end' };
                } else {
                    return { msg: 'Seansa Gir', status: true, type: 'enter' };
                }
            } else if (process == 'recordsEnd') {
                return { msg: 'Kayıtlar Sonlanmıştır', status: false, type: 'end' };
            } else {
                return { msg: 'Seans Sonlanmıştır', status: false, type: 'end' };
            }
        }
    } else {
        if (process == 'wait') {
            if (lastMin < -10) {
                return { msg: 'Seansın Süresi Geçmiş', status: false, type: 'end' };
            } else if (lastMin > 10) {
                return { msg: 'Seansa Kayıt Ol', status: true, type: (type == 'student' ? 'uregister' : 'register') };
            }else{
                 return { msg: 'Seans Başlamak Üzere', status: false, type: 'end' };
            }
        } else if (process == 'start') {
            if (lastMin < -150) {
                return { msg: 'Seans Sonlanmıştır', status: false, type: 'end' };
            } else {
                return { msg: 'Seans Başlamış', status: false, type: 'end' };
            }
        } else if (process == 'recordsEnd') {
            return { msg: 'Kayıtlar Sonlanmıştır', status: false, type: 'end' };
        } else {
            return { msg: 'Kayıt Sonlandırılmış', status: false, type: 'end' };
        }
    }
});

const rules = {
    sequelDate: { required: helpers.withMessage('Lütfen devam seansı için bir tarih seçiniz.', required), },
};

const $v = useVuelidate(rules, { sequelDate });


async function load() {
    try {
        loading.value = true;

        const id = currentRoute.value.params.id;
        const res = await axios.get('/api/sessions/' + id);
        state.value = res.data;

    } catch (error) {
        if (error.etype === 'response') {
            errorMessage.value = error.response.data.message;
            toast.error(errorMessage.value, 5000);
        } else {
            errorMessage.value = error.message;
            toast.error(error.message, 5000);
        }
    } finally {
        loading.value = false;
    }
}

async function takeAction() {
    try {
        const id = currentRoute.value.params.id;
        const info = statusInfo.value;

        if (info.type == "register") {
            let modal = document.getElementById("conceptModal");
            bootstrap.Modal.getOrCreateInstance(modal).show();
        } else if (info.type == "uregister") {
            sending.value = true;
            const res = await axios.post("/api/sessions/" + id + "/register", { conceptId: null });
            state.value.session.subscribed = res.data.subscribed;
            state.value.session.activeStudent++;
            toast.success(res.data.message, 5000);
        } else if (info.type == "exit") {
            sending.value = true;
            const res = await axios.delete("/api/sessions/" + id + "/exit");
            state.value.session.subscribed = res.data.subscribed;

            if (type == "student") {
                state.value.session.activeStudent--;
            } else {
                state.value.session.activeClient--;
            }

            toast.success(res.data.message, 5000);
        } else if (info.type == "start") {
            if (state.value.session.activeClient < 3) {
                toast.error('Seansı başlatmak için yeterli katılımcı bulunmuyor.', 5000);
            } else {
                sending.value = true;
                const res = await axios.put("/api/sessions/" + id + "/start");
                state.value.session.process = res.data.process;
                toast.success(res.data.message, 5000);
            }

        } else if (info.type == "enter") {
            if (state.value.session.activeClient < 3) {
                toast.error('Seansta yeterli katılımcı bulunmuyor.', 5000);
            } else {
                router.push({ name: 'canli', params: { id } });
            } 
        }
    } catch (error) {
        if (error.etype === 'response') {
            toast.error(error.response.data.message, 5000);
        } else {
            toast.error(error.message, 5000);
        }
    } finally {
        sending.value = false;
    }
}

async function register(conceptId) {
    try {

        sending.value = true;
        const id = currentRoute.value.params.id;


        let modal = document.getElementById("conceptModal");
        bootstrap.Modal.getOrCreateInstance(modal).hide();

        const res = await axios.post("/api/sessions/" + id + "/register", { conceptId });
        state.value.session.subscribed = res.data.subscribed;
        state.value.session.activeClient++;

        toast.success(res.data.message, 5000);
    } catch (error) {
        if (error.etype === 'response') {
            toast.error(error.response.data.message, 5000);
        } else {
            toast.error(error.message, 5000);
        }
    } finally {
        sending.value = false;
    }
}

async function cancelSession() {
    try {
        const ok = confirm('Seansı iptal etmek istediğinize emin misiniz?');

        if (ok && isOnline.value) {
            sending.value = true;
            const id = currentRoute.value.params.id;

            const res = await axios.put("/api/sessions/" + id + "/cancel");
            state.value.session.process = res.data.process;
            toast.success(res.data.message, 5000);
        }

    } catch (error) {
        if (error.etype === 'response') {
            toast.error(error.response.data.message, 5000);
        } else {
            toast.error(error.message, 5000);
        }
    } finally {
        sending.value = false;
    }
}


async function sequelSave() {
    $v.value.$touch();
    const errors = $v.value.$errors;
    await nextTick();

    if (errors.length) {
        const elem = document.querySelector(".is-invalid");
        if (elem) {
            elem.focus();
        }
    } else {
        sequelSaveData();
    }
}

async function sequelSaveData() {
    try {

        if (isOnline.value) {
            sequelSending.value = true;

            const id = currentRoute.value.params.id;

            const res = await axios.post('/api/sessions/' + id + '/create', {
                startDate: moment(sequelDate.value).format('YYYY-MM-DDTHH:mm'),
            });


            let modal = document.getElementById("sequelModal");
            bootstrap.Modal.getOrCreateInstance(modal).hide();

            toast.success(res.data.message, 5000);
            router.push({ path: '/' });
        }

    } catch (error) {
        if (error.etype === 'response') {
            toast.error(error.response.data.message, 5000);
        } else {
            toast.error(error.message, 5000);
        }
    } finally {
        sequelSending.value = false;
    }

}

onMounted(() => {
    setInterval(() => {
        now.value = moment();
    }, 30 * 1000);

    load();
});
</script>

<style lang="scss" scoped>
.session-title {
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 136.02%;
    margin: 0;
}

.session-category {
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 23px;

    color: #425466;
}

p.detail {
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: 0.05em;
    white-space: break-spaces;

    color: #16192c;
}

.sub-title {
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: -0.05em;

    color: #16192c;
}


.accordion-item,
.accordion-button {
    border: 0;
}

.accordion-button {
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    color: #6c63ff;

    &:not(.collapsed) {
        color: #6c63ff;
        background-color: #5a546f1a;
        box-shadow: unset;
    }

    &:focus {
        z-index: 3;
        border-color: unset;
        outline: 0;
        box-shadow: unset;
    }
}
</style>