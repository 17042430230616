<template>
    <div v-if="props.skeleton" class="card card-inner d-flex flex-row p-3">
        <div class="skeleton-square rounded-circle me-3 max-h-48"></div>
        <div class="flex-fill">
            <div class="skeleton-line h15 w-75"></div>
            <div class="skeleton-line h15 w-50"></div>
        </div>
    </div>

    <router-link
        v-if="!props.skeleton"
        :to="{ name: 'psikolog', params: { id: user.uid } }"
        class="card card-inner d-flex flex-row p-3"
    >
        <img
            :src="user.imageUrl ?? require('@/assets/user.svg')"
            class="rounded-circle me-3 max-h-48"
            alt
        />
        <div class="d-flex flex-column">
            {{ user.namesurname }}
            <StarRating
                class="mt-1"
                :rating="user.rating"
                :star-size="16"
                active-color="#6C63FF"
                :show-rating="false"
                :read-only="true"
            />
        </div>
    </router-link>
</template>
<script setup>
import StarRating from 'vue-star-rating';

const props = defineProps({
    item: Object,
    skeleton: {
        type: Boolean,
        default: false
    }
});

const user = props.item; 
</script>
<style type="scss" scoped>
a {
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 136.02%;
    text-decoration: none;
    color: #27272e;
}
</style>