<template>
  <div class="h-100-center">
    <div class="container py-5">
      <div class="row align-items-center">
        <div class="col">
          <form method="post">
            <div class="container">
              <p class="header-text my-5 text-center">YENİ ŞİFRENİZİ OLUŞTURUN</p>

              <div class="d-grid gap-3">
                <div>
                  <input type="password" class="form-control ps-4" :class="{ 'is-invalid': $v.password.$errors.length }"
                    placeholder="Parola" v-model="state.password" />
                  <invalid-message :errors="$v.password.$errors" />
                </div>
                <div>
                  <input type="password" class="form-control ps-4"
                    :class="{ 'is-invalid': $v.passwordAgain.$errors.length }" placeholder="Parola Tekrar"
                    v-model="state.passwordAgain" />
                  <invalid-message :errors="$v.passwordAgain.$errors" />
                </div>
              </div>

              <div class="d-grid gap-2 my-5 px-5">
                <button class="btn btn-primary" type="button" @click="save" :disabled="sending || !isOnline">
                  <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true" v-if="sending"></span>
                  <span class="visually-hidden">Şifre Kayıt Ediliyor...</span>
                  <span v-if="!sending" class="mx-2">{{ isOnline ? 'Oluştur' : 'İnternet Bağlantınız Yok' }}</span>
                </button>
                <router-link :to="{ name: 'giris' }" class="btn btn-secondary">
                  İptal
                </router-link>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import md5 from 'md5';
import { useConAware } from "@/utils/connectionAware";
import { useVuelidate } from '@vuelidate/core';
import { required, minLength, helpers } from '@vuelidate/validators';
import { ref, inject, nextTick, computed } from 'vue';
import {  useRouter } from 'vue-router';


const router = useRouter();
const { isOnline } = useConAware();
const axios = inject('axios');
const toast = inject('toast');
 const currentRoute = computed(() => {
    return router.currentRoute.value;
});

const sending = ref(false);
const state = ref({
  password: '',
  passwordAgain: '',
});


const mustBeSame =
  (value) => {
    return value === state.value.password
  };

const rules = {
  password: { required: helpers.withMessage('Lütfen kullanmak istediğiniz şifrenizi yazınız.', required), minLength: helpers.withMessage('Parolanız en az 6 karakter olmalıdır.', minLength(6)), },
  passwordAgain: {
    required: helpers.withMessage('Lütfen kullanmak istediğiniz şifrenizi yazınız.', required), minLength: helpers.withMessage('Parolanız en az 6 karakter olmalıdır.', minLength(6)), mustBeSame: helpers.withMessage('Parolanız yukarıdaki ile uyuşmuyor.', mustBeSame)
  },
};

const $v = useVuelidate(rules, state);

function focusHasErrors() {
  const elem = document.querySelector(".is-invalid");
  if (elem) {
    elem.focus();
  }
}

async function save() {
  $v.value.$touch();
  const errors = $v.value.$errors;
  await nextTick();

  if (errors.length) {
    focusHasErrors();
  } else {
    saveData();
  }
}

async function saveData() {
  try {
    if (isOnline.value) {
      sending.value = true;
 
      const hash = currentRoute.value.params.hash;
      const res = await axios.post('/api/login/renew', {
        password: md5(state.value.password),
        hash
      });

      toast.success(res.data.message);
      router.replace({ path: '/' });
    }

  } catch (error) {
    if (error.etype === 'response') {
      toast.error(error.response.data.message, 5000);
    } else {
      toast.error(error.message, 5000);
    }
  } finally {
    sending.value = false;
  }

} 
</script>


<style lang="scss" scoped>
.header-text {
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 150%;
  color: rgba(0, 0, 0, 0.5);
}


@media (min-width: 0) and (max-width: 576px) {
  .container {
    width: 100%;
  }
}

@media (min-width: 576px) and (max-width: 1200px) {
  .container {
    width: 70%;
  }
}

@media (min-width: 1200px) {
  .container {
    width: 50%;
  }
}
</style>