<template>
    <div v-if="props.skeleton" class="card card-inner p-3">
        <div class="skeleton-line h15 w-75"></div>
        <div class="skeleton-line h15 w-50"></div>
        <div class="d-flex align-items-center">
            <div class="skeleton-square rounded-circle me-3 max-h-48"></div>
            <div class="skeleton-line h15 w-50"></div>
        </div>
    </div>

    <router-link v-if="!props.skeleton" :to="{ name: 'seans', params: { id: session.sid } }"
        class="card session-item p-3">
        <div class="d-flex flex-column">
            <div class="d-flex">
                <h5 class="session-title m-0">{{ session.title }}</h5>
                <span class="ms-auto d-flex align-items-center">
                    <i v-if="session.access == 'private'" class="gi-lock" style="font-size: 20px;" v-tooltip
                        title="Kapalı Gurup"></i>
                    <i v-if="session.process == 'start'" class="gi-live text-primary ms-2" style="font-size: 24px;"
                        v-tooltip title="Seans Başladı"></i>
                </span>
            </div>
            <h6 class="session-category m-0">{{ session.category }} - {{ $filters.formatDate(session.startDate, 'DD.MM.YYYY') }}</h6>
            <div class="mt-3 session-title">
                <img :src="session.imageUrl ?? require('@/assets/user.svg')" class="rounded-circle me-3 max-h-48" />
                {{ session.psychologist }}
            </div>
            <div class="d-flex justify-content-between align-items-center mt-4 mb-3 stat">
                <span>
                    <i class="gi-persons"></i>
                    {{ session.activeClient }} / {{ session.maxClient }}
                </span>
                <span>
                    <i class="gi-student"></i>
                    {{ session.activeStudent }} / {{ session.maxStudent }}
                </span>
                <span v-tooltip :title="$filters.formatDate(session.startDate, 'DD MMMM')">
                    <i class="gi-clock"></i>
                    {{ $filters.formatDate(session.startDate, 'HH:mm') }}
                </span>
                <span>
                    <i class="gi-coins"></i>
                    {{ session.ticketCount*100 }} TL
                </span>
            </div>
        </div>
    </router-link>
</template>
<script setup>
const props = defineProps({
    item: Object,
    skeleton: {
        type: Boolean,
        default: false
    }
});

const session = props.item; 
</script>


<style type="scss" scoped>
a {
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 136.02%;
    text-decoration: none;
    color: #27272e;
}

.session-item .session-category {
    font-weight: 400;
    font-size: 14px;
    line-height: 23px;

    color: #425466;
}

.session-item .session-title {
    font-weight: 600;
    font-size: 16px;
    line-height: 136.02%;

    color: #27272e;
}

.session-item .stat {
    font-size: 16px;
}

.session-item .stat span {
    display: flex;
    align-items: center;
    font-weight: 600;
}

.session-item .stat i {
    font-size: 20px;
    margin-right: 10px;
}
</style>