<template>
    <div class="container-fluid">
        <h1 class="page-heading mx-0">Kategoriler</h1>

        <div class="row justify-content-center my-4">
            <div class="col col-xl-4 col-lg-6 col-md card py-3 px-4 mx-2 mx-xl-0 mx-lg-0">
                <form @submit.prevent="load">
                    <div class="d-flex align-items-center" style="position: relative;">
                        <input v-model="search" type="text" class="form-control light" placeholder="Kategori ara" />
                        <button class="btn btn-primary btn-sm no-shadow float-end px-4 py-2"
                            style="position:absolute; right:5px; " type="submit">Ara</button>
                    </div>
                </form>
            </div>
        </div>

        <div class="row">
            <template v-if="loading">
                <div class="col col-xl-3 col-lg-4 col-md-6 p-2 p-xl-3 p-lg-3" style="flex-basis: auto;" v-for="n in 20"
                    :key="'ps-' + n">
                    <div class="card card-inner d-flex align-items-center py-5 px-3">
                        <div class="skeleton-square h15 w-75"></div>
                        <div class="skeleton-square h15 w-50 mt-2"></div>
                    </div>
                </div>
            </template>

            <div v-if="!loading && !categories.length" class="my-3 py-5 text-center">
                <img class="img-fluid mb-4" src="@/assets/login.svg" style="height: 350px;" />
                <p v-if="search">Aradığınız kritere uygun bir kategori bulunmamaktadır.</p>
                <p v-else>Henüz kayıtlı bir kategori bulunmamaktadır.</p>
            </div>

            <div class="col col-xl-3 col-lg-4 col-md-6 p-2 p-xl-3 p-lg-3 d-flex" style="flex-basis: auto;"
                v-for="cat in categories" :key="cat.cid">
                <button @click="showModal(cat)" type="button"
                    class="card card-primary p-5 category-item">{{
                        cat.title
                    }}</button>
            </div>
        </div>
        <div v-if="categories.length && !noMore" class="d-flex justify-content-center p-5">
            <button class="btn btn-light text-purple" @click="loadMore">
                <span class="spinner-border spinner-border-sm mx-5" role="status" aria-hidden="true"
                    v-if="loadingMore"></span>
                <span class="visually-hidden">Bilgiler getiriliyor...</span>
                <span v-if="!loadingMore" class="mx-2">{{ !loadingMore ? 'Daha Fazla Göster' : '' }}</span>
            </button>
        </div>
        <Teleport to="body">
            <div class="modal fade" id="categoryModal" tabindex="-1">
                <div class="modal-dialog modal-lg modal-dialog-centered">
                    <div class="modal-content">
                         <div class="text-end">
                             <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                         </div>
                        <div class="modal-body">
                            <h5 class="modal-title text-center" id="staticBackdropLabel">{{ selected.title }} </h5>
                            <p v-html="selected.description"></p>
                        </div>

                        <div class="modal-footer border-0 justify-content-center">
                            <button @click="$router.push({ name: 'kategori-seans', params: { id: selected.cid ?? 0 } })"
                                class="btn btn-primary no-shadow text-white" data-bs-dismiss="modal">Seansları
                                Göster</button>
                        </div>
                    </div>
                </div>
            </div>
        </Teleport>
    </div>
</template>


<script setup>
import _ from 'lodash';
import { ref, onMounted, inject } from 'vue';
const axios = inject('axios');
const bootstrap = inject('bootstrap');


const errorMessage = ref('');
const loading = ref(true);
const loadingMore = ref(false);
const noMore = ref(false);
const search = ref(null);
const categories = ref([]);
let page = 1;

const selected = ref({ title: '', description: '' });


async function load() {

    try {
        loadingMore.value = false;
        loading.value = true;
        categories.value = [];
        page = 1;
        const res = await axios.post('/api/categories', { page, search: search.value });
        categories.value = res.data.categories;
    } catch (error) {
        if (error.etype === 'response') {
            errorMessage.value = error.response.data.message;
        } else {
            errorMessage.value = error.message;
        }
    } finally {
        loading.value = false;
    }
}

async function loadMore() {

    try {
        loadingMore.value = true;
        const res = await axios.post('/api/categories', { page: page + 1, search: search.value });
        if (res.data.categories.length) {
            categories.value = _.concat(categories.value, res.data.categories);
            page = page + 1;
        } else {
            noMore.value = true;
        }

    } catch (error) {
        if (error.etype === 'response') {
            errorMessage.value = error.response.data.message;
        } else {
            errorMessage.value = error.message;
        }
    } finally {
        loadingMore.value = false;
    }
}

function showModal(cat) {
    selected.value = cat;

    let modal = document.getElementById("categoryModal");
    bootstrap.Modal.getOrCreateInstance(modal).show();
}

onMounted(() => {
    load();
})


</script>


<style lang="scss" scoped>
a {
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 136.02%;
    text-decoration: none;
    color: #27272e;
}

.category-item {
    height: 120px;
    display:flex;
    align-items:center;
    justify-content:center;
    color:white;
    flex: 1 1 auto;
}

.modal-content {
    background: #b3a7dd;
    border-radius: 12px;
    color: white;
    padding: 40px;

    h5 {
        font-weight: 700;
        font-size: 32px;
        line-height: 120%;
        margin-bottom: 12px;
    }

    p {
        font-weight: 500;
        font-size: 16px;
        line-height: 150%;
        text-align: justify;
        color: rgba(255, 255, 255, 0.7);

    }
}
</style>