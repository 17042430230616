<template>
    <div class="container-fluid">
        <div class="d-flex align-items-center">
            <h1 class="page-heading mx-0">
                <div v-if="loading && !category.title" class="skeleton-line h25 w-25" style="min-width: 200px;"></div>
                {{ category.title }}
            </h1>
            <button v-if="category.title" @click="toggleSubscribe" type="button"
                class="rounded-circle bg-light ms-auto border-0  d-flex justify-content-center align-items-center"
                style="width:50px;height:50px;" v-tooltip
                :data-bs-original-title="category.subscribed ? 'Bildirimleri Kapat' : 'Bildirimlerden Haberdar Ol'">
                <i class="gi-add-bell" :class="{ 'text-success fw-bold': category.subscribed }"
                    style="font-size:20px"></i>
            </button>
        </div>

        <div class="container d-flex justify-content-center">
            <DateSelector v-model="activeDate" />
        </div>
        <div
            class="container d-flex justify-content-start justify-content-xl-center justify-content-lg-center mt-3 px-3">
            <div class="form-check form-switch">
                <input v-model="showAll" class="form-check-input" type="checkbox" role="switch"
                    style="height: 1.5em;    width: 2.5em;" id="flexSwitchCheckDefault" />
                <label class="form-check-label ms-2" for="flexSwitchCheckDefault" style="vertical-align:sub;">{{
                        showAll
                            ? 'Tümünü Göster' : 'Sadece Benimkiler'
                }}</label>
            </div>
        </div>

        <div class="row">
            <template v-if="loading">
                <div class="col col-xl-4 col-lg-6 col-md-6 p-2 p-xl-3 p-lg-3" style="flex-basis: auto;" v-for="n in 20"
                    :key="'ps-' + n">
                    <SessionCard :skeleton="true" />
                </div>
            </template>

            <div v-if="!loading && !sessions.length" class="my-3 py-5 text-center">
                <img class="img-fluid mb-4" src="@/assets/no-session.svg" style="height: 350px;" />
                <p>
                    <b>{{ $filters.formatDate(activeDate, 'DD MMMM YYYY') }}</b> için henüz bir seans bulunmamaktadır,
                    <br />sağ üst köşedeki bildirim ikonuna tıklayıp gelecek olan seanslardan haberdar olabilirsiniz.
                </p>
            </div>

            <div class="col col-xl-4 col-lg-6 col-md-6 p-2 p-xl-3 p-lg-3" style="flex-basis: auto;"
                v-for="session in sessions" :key="session.sid">
                <SessionCard :item="session" />
            </div>
        </div>
        <div v-if="sessions.length && !noMore" class="d-flex justify-content-center p-5">
            <button class="btn btn-light text-purple" @click="loadMore">
                <span class="spinner-border spinner-border-sm mx-5" role="status" aria-hidden="true"
                    v-if="loadingMore"></span>
                <span class="visually-hidden">Bilgiler getiriliyor...</span>
                <span v-if="!loadingMore" class="mx-2">{{ !loadingMore ? 'Daha Fazla Göster' : '' }}</span>
            </button>
        </div>
    </div>
</template>


<script setup>
import _ from 'lodash';
import DateSelector from '@/components/customs/DateSelector.vue';
import SessionCard from '@/components/cards/SessionCard.vue';
import { ref, onMounted, inject, computed, watch } from 'vue';
import { useRoute } from 'vue-router';

const toast = inject('toast');
const axios = inject('axios');
const moment = inject('moment');

const activeDate = ref(null);
const showAll = ref(true);
const loading = ref(true);
const loadingMore = ref(false);
const noMore = ref(false);
const category = ref({ title: '', description: '' });
const sessions = ref([]);
let page = 1;

const currentRoute = computed(() => {
    return useRoute()
});


watch(activeDate, load);

watch(showAll, load);


async function load() {
    try {
        page = 1;
        loadingMore.value = false;
        sessions.value = [];
        loading.value = true;

        const id = currentRoute.value.params.id;
        let date = activeDate.value ? moment(activeDate.value).format('YYYY-MM-DD') : null;
        const res = await axios.post('/api/categories/' + id, { page, all: showAll.value, date });
        sessions.value = res.data.sessions;
        category.value = res.data.category;

        document.title = category.value.title;
    } catch (error) {
        if (error.etype === 'response') {
            toast.error(error.response.data.message);
        } else {
            toast.error(error.message);
        }
    } finally {
        loading.value = false;
    }
}

async function toggleSubscribe() {
    try {
        category.value.subscribed = !category.value.subscribed;

        const id = currentRoute.value.params.id;
        const res = await axios.put('/api/categories/' + id + "/sucscribe");
        category.value.subscribed = res.data.subscribed;
        if (res.data.subscribed) {
            toast.success(res.data.message, 5000);
        } else {
            toast.info(res.data.message, 5000);
        }
    } catch (error) {
        if (error.etype === 'response') {
            toast.error(error.response.data.message);
        } else {
            toast.error(error.message);
        }
        category.value.subscribed = !category.value.subscribed;
    }
}

async function loadMore() {
    try {
        loadingMore.value = true;
        const id = currentRoute.value.params.id;
        let date = activeDate.value ? moment(activeDate.value).format('YYYY-MM-DD') : null;
        const res = await axios.post('/api/categories/' + id, { page: page + 1, all: showAll.value, date });
        if (res.data.sessions.length) {
            sessions.value = _.concat(sessions.value, res.data.sessions);
            page = page + 1;
        } else {
            noMore.value = true;
        }

    } catch (error) {
        if (error.etype === 'response') {
            toast.error(error.response.data.message);
        } else {
            toast.error(error.message);
        }
    } finally {
        loadingMore.value = false;
    }
}

onMounted(() => {
    load();
});
</script>
 