<template>
  <div class="h-100-center">
    <div class="container py-5">
      <div class="row align-items-center">
        <div class="col">
          <div class="container text-center pt-5 pt-xl-0 pt-lg-0">
            <img class="img-fluid" src="@/assets/whoareyou.svg" />
            <p class="who-text my-5">Uygulamayı Kim Olarak Kullanacaksınız?</p>

            <div class="d-grid gap-4">
              <router-link to="/kayit/psikolog" class="btn btn-primary">Psikolog</router-link>
              <router-link to="/kayit/kullanici" class="btn btn-primary">Danışan</router-link>
              <router-link to="/kayit/stajyer" class="btn btn-primary">Stajyer</router-link>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.who-text {
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 150%;
  color: rgba(0, 0, 0, 0.5);
}

@media (min-width: 0) and (max-width: 576px) {
  .container {
    width: 100%;
  }
}

@media (min-width: 576px) {
  .container {
    width: 60%;
  }
}
</style>