export function useActiveUser() {
  const name = localStorage.getItem("name");

  const type = (() => {
    const type = localStorage.getItem("type");

    if (type == "1") return "user";
    if (type == "2") return "student";
    if (type == "3") return "psychologist";

    return "";
  })();

  const isLoggedin =
    localStorage.getItem("acctoken") != null &&
    localStorage.getItem("acctoken") != "";

  return { name, type, isLoggedin };
}
