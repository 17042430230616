<template>
  <div class="file-container" :class="dragging ? 'dragging' : ''" @dragover.prevent="dragging = true"
    @dragleave.prevent="dragging = false" @drop.prevent="drop" @click="openInput">
    <img class="img-fluid rounded-circle" :class="{ 'max-h-100': imageMode , 'edit-mode': editMode }"
      :src="imageMode && previewImage != null ? previewImage : image" />
    <h3 v-if="title">{{ title }}</h3>
    <p v-if="description">{{ alsoDescription }}</p>
    <ul v-if="!imageMode" class="list-group list-group-flush">
      <li class="list-group-item d-flex justify-content-between align-items-center" v-for="file in files"
        :key="file.name">
        <small>{{ file.name }}</small>
        <button type="button" class="btn-close" @click.stop="() => removeFile(file)"></button>
      </li>
    </ul>
    <div v-if="errors.length" class="alert alert-danger m-0">
      <ul class="m-0">
        <li class="p-0 text-start" v-for="(error, i) in errors" :key="i">{{ error }}</li>
      </ul>
    </div>
  </div>
</template>

<script setup>
import _ from 'lodash';
import { ref, toRefs, computed, watch } from 'vue';
const inputEl = document.createElement('input');
inputEl.type = 'file';
inputEl.addEventListener("change", () => drop({ dataTransfer: { files: inputEl.files } }));


const emit = defineEmits(['update:modelValue']);

const props = defineProps({
  title: { type: String },
  description: { type: String },
  image: { type: String, required: true },
  accept: { type: Array },
  maxFileSize: { type: Number, default: 0 },
  maxFiles: { type: Number, default: 0 },
  imageMode: { type: Boolean, default: false },
  editMode: { type: Boolean, default: false }
});

if (props.accept) {
  inputEl.accept = props.accept.join(', ');
}

inputEl.multiple = props.maxFiles == 0 || props.maxFiles > 1;


const { title, description, image, imageMode } = toRefs(props);
const files = ref([]);
const errors = ref([]);
const dragging = ref(false);
const previewImage = ref(null);

const hasFiles = computed(() => {
  return files.value.length > 0;
});

const fileNames = computed(() => {
  return hasFiles.value ? files.value.map(e => e.name).join(', ') : '';
});

const alsoDescription = computed(() => {
  return dragging.value ? 'Dosyayı buraya bırakınız.' : (hasFiles.value ? fileNames : description.value);
});


function openInput() {
  inputEl.click();
}

function drop(e) {
  errors.value = [];
  files.value = [];
  dragging.value = false;

  let _files = e.dataTransfer.files || e.target.files;
  _files = [..._files];

  if (_.some(_files, (f) => !isValidFile(f.name))) {
    errors.value.push('Sadece ' + props.accept.join(', ') + ' uzantılarına sahip dosyalar yükleyebilirsiniz.');
  }
  if (_.some(_files, (f) => !isValidSize(f.size))) {
    errors.value.push('En fazla ' + props.maxFileSize + " MB'lık dosya yükleyebilirsiniz.");
  }
  if (props.maxFiles > 0 && _files.length > props.maxFiles) {
    errors.value.push('En fazla ' + props.maxFiles + " adet dosya yükleyebilirsiniz.");
  }

  if (errors.value.length > 0) {
    return;
  }

  files.value = _files;

}

function isValidFile(name) {
  let exts = name.split('.').pop();
  if (!_.isEmpty(props.accept)) {
    return _.includes(props.accept, '.' + _.lowerCase(exts));
  }

  return true;
}

function isValidSize(size) {
  if (props.maxFileSize > 0) {
    return (Math.round(size / 1024 / 10.24) / 100) <= props.maxFileSize;
  }

  return true;
}

function removeFile(file) {
  _.remove(files.value, (e) => e.name == file.name);
  updateModel();
}


function generatePreview() {
  previewImage.value = null;
  if (files.value != null && files.value.length && files.value[0] != null) {
    let reader = new FileReader()
    reader.readAsDataURL(files.value[0])
    reader.onloadend = () => {
      previewImage.value = reader.result;
    }
  }

}

function updateModel() {
  emit('update:modelValue', props.maxFiles > 1 || props.maxFiles == 0 ? files.value : files.value.length ? files.value[0] : null);
  if (props.imageMode) {
    generatePreview();
  }
}

watch(files, updateModel);
</script>

<style type="scss" scoped>
.max-h-100 {
  height: 100px;
  width: 100px;
  object-fit: cover;
}

.edit-mode {
  border: 2px dashed #6b64fc;
  padding: 5px;
}
</style>