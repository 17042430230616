<template>
  <div class="container py-5">
    <div class="row align-items-center">
      <div class="col">
        <form method="post" enctype="multipart/form-data">
          <div class="container">
            <p class="header-text my-5 text-center">PSİKOLOG KAYIT FORMU</p>

            <h6 class="header-sub">Kişisel Bilgiler</h6>
            <div class="d-grid gap-3 my-3">
              <div>
                <input class="form-control ps-4" :class="{ 'is-invalid': $v.name.$errors.length }" placeholder="Ad"
                  v-model="state.name" />
                <invalid-message :errors="$v.name.$errors" />
              </div>
              <div>
                <input class="form-control ps-4" :class="{ 'is-invalid': $v.lastname.$errors.length }"
                  placeholder="Soyad" v-model="state.lastname" />
                <invalid-message :errors="$v.lastname.$errors" />
              </div>
              <div>
                <input type="email" class="form-control ps-4" :class="{ 'is-invalid': $v.email.$errors.length }"
                  placeholder="Email" v-model="state.email" />
                <invalid-message :errors="$v.email.$errors" />
              </div>
              <div>
                <input type="tel" v-mask="'+90 (1##) ###-####'" class="form-control ps-4"
                  :class="{ 'is-invalid': $v.phone.$errors.length }" placeholder="Telefon Numarası"
                  v-model="state.phone" />
                <invalid-message :errors="$v.phone.$errors" />
              </div>
              <div>
                <input type="password" class="form-control ps-4" :class="{ 'is-invalid': $v.password.$errors.length }"
                  placeholder="Parola" v-model="state.password" />
                <invalid-message :errors="$v.password.$errors" />
              </div>
              <div>
                <input type="password" class="form-control ps-4"
                  :class="{ 'is-invalid': $v.passwordAgain.$errors.length }" placeholder="Parola Tekrar"
                  v-model="state.passwordAgain" />
                <invalid-message :errors="$v.passwordAgain.$errors" />
              </div>

              <v-date-picker v-model="state.birthday" mode="date" :max-date="new Date()"
                :attributes="[{ key: 'today', highlight: true, dates: new Date(), }]">
                <template v-slot="{ inputValue, inputEvents }">
                  <input class="form-control ps-4" :class="{ 'is-invalid': $v.birthday.$errors.length }"
                    :value="inputValue" v-on="inputEvents" placeholder="Doğum Tarihi" v-mask="'##.##.####'"
                    autocomplete="off" />
                  <invalid-message :errors="$v.birthday.$errors" />
                </template>
              </v-date-picker>
            </div>
            <h6 class="header-sub mt-4">
              Eğitim Bilgileri
              <button type="button" class="add-btn float-end" @click="addSchool">
                <i class="gi-add"></i>
              </button>
            </h6>
            <div tabindex="-1" class="alert alert-danger mt-4" role="alert"
              :class="{ 'is-invalid': $v.educationNeeded.$errors.length }" v-for="error of $v.educationNeeded.$errors"
              :key="error.$uid">{{ error.$message }}</div>

            <div class="accordion accordion-flush" id="egitimAccordion">
              <div class="accordion-item" v-for="(education, i) in state.educations" :key="'educations-' + i">
                <h2 class="accordion-header" :id="'heading-' + i">
                  <button class="accordion-button" type="button" data-bs-toggle="collapse"
                    :data-bs-target="'#collapse-' + i" aria-expanded="false" :aria-controls="'collapse-' + i">
                    {{
                      education.schoolName
                    }}
                  </button>
                </h2>
                <div :id="'collapse-' + i" class="accordion-collapse collapse show" data-bs-parent="#egitimAccordion">
                  <div class="accordion-body p-0">
                    <div class="d-grid gap-3 my-3">
                      <div>
                        <input class="form-control ps-4"
                          :class="{ 'is-invalid': $v.educations.$each.$response.$errors[i].schoolName.length }"
                          placeholder="Okul İsmi" v-model="education.schoolName" />
                        <invalid-message :errors="$v.educations.$each.$response.$errors[i].schoolName" />
                      </div>
                      <div>
                        <select class="form-select ps-4"
                          :class="{ 'is-invalid': $v.educations.$each.$response.$errors[i].degree.length }"
                          v-model="education.degree">
                          <option :value="null" disabled>Derece</option>
                          <option value="Lisans">Lisans</option>
                          <option value="Yüksek Lisans">Yüksek Lisans</option>
                          <option value="Doktora">Doktora</option>
                        </select>
                        <invalid-message :errors="$v.educations.$each.$response.$errors[i].degree" />
                      </div>
                      <div>
                        <v-date-picker v-model="education.startDate" mode="date" :max-date="new Date()">
                          <template v-slot="{ inputValue, inputEvents }">
                            <input class="form-control mb-2 ps-4"
                              :class="{ 'is-invalid': $v.educations.$each.$response.$errors[i].startDate.length }"
                              placeholder="Başlangıç Tarihi" :value="inputValue" v-on="inputEvents"
                              v-mask="'##.##.####'" autocomplete="off" />
                          </template>
                        </v-date-picker>
                        <invalid-message :errors="$v.educations.$each.$response.$errors[i].startDate" />
                      </div>
                      <div>
                        <v-date-picker v-model="education.endDate" mode="date" :max-date="new Date()">
                          <template v-slot="{ inputValue, inputEvents }">
                            <input class="form-control mb-2 ps-4"
                              :class="{ 'is-invalid': $v.educations.$each.$response.$errors[i].endDate.length }"
                              placeholder="Bitiş Tarihi" :value="inputValue" v-on="inputEvents" v-mask="'##.##.####'"
                              autocomplete="off" />
                          </template>
                        </v-date-picker>
                        <invalid-message :errors="$v.educations.$each.$response.$errors[i].endDate" />
                      </div>

                      <FileInput tabindex="-1" v-model="education.file" :maxFileSize="3" :maxFiles="1"
                        :accept="['.jpg', '.jpeg', '.png', '.bmp', '.pdf']" title="Mezuniyet Belgesi"
                        description="Barkodlu e-devlet çıktısı yükleyiniz!" :image="require('@/assets/file_add.svg')"
                        :class="{ 'is-invalid': $v.educations.$each.$response.$errors[i].file.length, 'success': education.file != null }">
                      </FileInput>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <h6 class="header-sub my-3" tabindex="-1">Sertifika Bilgileri</h6>
            <FileInput tabindex="-1" key="certificates" v-model="state.certificates" :maxFileSize="3"
              :accept="['.jpg', '.jpeg', '.png', '.bmp', '.pdf']" title="Sertifika Belgesi"
              description="Sertifikalarınızı belgelerinizi buradan yükleyiniz!"
              :image="require('@/assets/file_add.svg')"
              :class="{ 'success': state.certificates.length, 'is-invalid': $v.certificates.$errors.length }">
            </FileInput>
            <h6 class="header-sub mt-4">
              Uzmanluk Alanları
              <button type="button" class="add-btn float-end" @click="addExpert">
                <i class="gi-add"></i>
              </button>
            </h6>
            <div tabindex="-1" class="alert alert-danger mt-4" role="alert"
              :class="{ 'is-invalid': $v.experts.$errors.length }" v-for="error of $v.experts.$errors"
              :key="error.$uid">{{ error.$message }}</div>
            <div class="d-grid gap-3 my-3" v-for="(exp, i) in state.experts" :key="'exp' + i">
              <input class="form-control ps-4" placeholder="Yeni Uzmanlık" v-model="exp.name" />
            </div>
            <h6 class="header-sub my-3">Biyografi</h6>
            <textarea class="form-control" rows="7"
              placeholder="Bu kısım profilinizde 'Hakkımda' kısmı olarak görünecektir."
              :class="{ 'is-invalid': $v.bio.$errors.length }" v-model="state.bio"></textarea>
            <invalid-message :errors="$v.bio.$errors" />
            <h6 class="header-sub my-3">Neden Gurup?</h6>
            <textarea class="form-control" rows="7" placeholder="Bizi tercih etme sebebiniz nedir?"
              :class="{ 'is-invalid': $v.whygurup.$errors.length }" v-model="state.whygurup"></textarea>
            <invalid-message :errors="$v.whygurup.$errors" />
            <div class="text-center mt-5">
              <h6 class="header-sub mb-0">Resim Ekle</h6>
              <FileInput key="profilePicture" tabindex="-1" v-model="state.profilePicture" :maxFileSize="2"
                :maxFiles="1" :imageMode="true" :accept="['.jpg', '.jpeg', '.png', '.bmp']"
                :image="require('@/assets/user.svg')" class="border-0 d-inline-flex"
                :class="{ 'success': state.profilePicture != null, 'is-invalid': $v.profilePicture.$errors.length }">
              </FileInput>
              <invalid-message :errors="$v.profilePicture.$errors" />
            </div>
            <div class="form-check mt-3">
              <input class="form-check-input" type="checkbox" v-model="state.readed" id="term-check"
                :class="{ 'is-invalid': $v.readed.$errors.length }" />
              <label class="form-check-label user-term" for="term-check">
                <a href="/kayit-uyelik-sozlesmesi-psikolog" target="_blank">Üyelik sözleşmesini</a> okudum ve onaylıyorum.
              </label>
            </div>
            <div class="d-grid gap-2 my-5 px-5">
              <button class="btn btn-primary" type="button" @click="save" :disabled="sending || !isOnline">
                <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true" v-if="sending"></span>
                <span class="visually-hidden">Kaydınız Yapılıyor...</span>
                <span v-if="!sending" class="mx-2">{{ isOnline ? 'Kayıt Ol' : 'İnternet Bağlantınız Yok' }}</span>
              </button>
              <router-link :to="{ name: 'kayit' }" class="btn btn-secondary">
                İptal
              </router-link>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<script setup>
import md5 from 'md5';
import FileInput from "@/components/customs/FileInput.vue";
import { useConAware } from "@/utils/connectionAware";
import { useVuelidate } from '@vuelidate/core';
import { required, email, minLength, helpers } from '@vuelidate/validators';
import { ref, inject, nextTick } from 'vue';
import { useRouter } from 'vue-router';


const router = useRouter();
const { isOnline } = useConAware();
const axios = inject('axios');
const toast = inject('toast');

const sending = ref(false);
const state = ref({
  name: '',
  lastname: '',
  email: '',
  phone: '',
  password: '',
  passwordAgain: '',
  birthday: null,
  educations: [],
  certificates: [],
  experts: [],
  bio: '',
  whygurup: '',
  readed: false,
  profilePicture: null,
});


const mustBeSame =
  (value) => {
    return value === state.value.password
  };

const rules = {
  name: { required: helpers.withMessage('Adınızı bilmemiz gerekiyor.', required) },
  lastname: { required: helpers.withMessage('Soyadınızı bilmemiz gerekiyor.', required) },
  email: { required: helpers.withMessage('Lütfen email adresinizi yazınız.', required), email: helpers.withMessage('Lütfen geçerli bir email adresi yazınız.', email) },
  phone: { required: helpers.withMessage('Lütfen telefon numaranızı yazınız.', required) },
  password: { required: helpers.withMessage('Lütfen kullanmak istediğiniz şifrenizi yazınız.', required), minLength: helpers.withMessage('Parolanız en az 6 karakter olmalıdır.', minLength(6)), },
  passwordAgain: {
    required: helpers.withMessage('Lütfen kullanmak istediğiniz şifrenizi yazınız.', required), minLength: helpers.withMessage('Parolanız en az 6 karakter olmalıdır.', minLength(6)), mustBeSame: helpers.withMessage('Parolanız yukarıdaki ile uyuşmuyor.', mustBeSame)
  },
  birthday: { required: helpers.withMessage('Lütfen doğum tarihinizi yazınız.', required) },
  bio: { required: helpers.withMessage('Lütfen biyografinizi daha detaylı bir şekilde doldurun.', required) },
  whygurup: { required: helpers.withMessage("Lütfen Gurup'u neden tercih ettiğinizi açıklayınız.", required) },
  profilePicture: { required: helpers.withMessage("Lütfen bir profil resmi yükleyiniz.", (v) => v != null) },
  readed: { required: helpers.withMessage("Lütfen üyelik sözleşmesini okuyup onaylayınız.", (v) => v === true) },
  educationNeeded: {
    required: helpers.withMessage("Lütfen en az bir tane eğitim bilgisi ekleyiniz.", () => state.value.educations.length > 0)
  },
  certificates: {
    required: helpers.withMessage("Lütfen en az bir tane sertifika ekleyiniz.", () => state.value.certificates.length > 0)
  },
  experts: {
    required: helpers.withMessage("Lütfen en az bir tane uzmanlık bilgisi ekleyiniz.", () => state.value.experts.length > 0)
  },
  educations: {
    $each: helpers.forEach({
      schoolName: {
        required: helpers.withMessage('Eğitim aldığınız okulun ismini giriniz.', required)
      },
      degree: {
        required: helpers.withMessage('Eğitim derecesini seçiniz.', required)
      },
      startDate: {
        required: helpers.withMessage('Ne zaman başladınız.', required)
      },
      endDate: {
        required: helpers.withMessage('Ne zaman bitirdiniz.', required)
      },
      file: {
        required: helpers.withMessage('Mezuniyet belgenizi yükleyiniz.', required)
      },
    }),
  },

};

const $v = useVuelidate(rules, state);

function focusHasErrors() {
  const elem = document.querySelector(".is-invalid");
  if (elem) {
    elem.focus();
  }
}


async function save() {
  $v.value.$touch();
  const errors = $v.value.$errors;
  await nextTick();

  if (errors.length) {
    focusHasErrors();
  } else {
    saveData();
  }
}

function addSchool() {
  state.value.educations.push({
    schoolName: 'Okul 1',
    degree: null,
    startDate: null,
    endDate: null,
    file: null
  });
}

function addExpert() {
  state.value.experts.push({ name: '' });
}

async function saveData() {
  try {
    const formData = new FormData();
    formData.append("name", state.value.name);
    formData.append("lastname", state.value.lastname);
    formData.append("email", state.value.email);
    formData.append("phone", state.value.phone);
    formData.append("password", md5(state.value.password));
    formData.append("birthday", state.value.birthday?.toISOString());
    formData.append("bio", state.value.bio);
    formData.append("whygurup", state.value.whygurup);
    formData.append("profilePicture", state.value.profilePicture);

    let i = 0;
    for (const ser of state.value.certificates) {
      formData.append("certificates[" + i + "]", ser);
      i++;
    }

    i = 0;
    for (const eg of state.value.educations) {
      formData.append("educations[" + i + "][schoolName]", eg.schoolName);
      formData.append("educations[" + i + "][degree]", eg.degree);
      formData.append("educations[" + i + "][startDate]", eg.startDate?.toISOString());
      formData.append("educations[" + i + "][endDate]", eg.endDate?.toISOString());
      formData.append("educations[" + i + "][order]", i);
      formData.append("educations[" + i + "]", eg.file);
      i++;
    }

    i = 0;
    for (const uz of state.value.experts) {
      formData.append("experts[" + i + "][name]", uz.name);
      i++;
    }
    if (isOnline.value) {
      sending.value = true;
      const res = await axios.post('/api/register/psychologist', formData, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      });
      toast.success(res.data.message);

      localStorage.setItem("acctoken", res.data.token);
      localStorage.setItem("name", res.data.adsoyad);
      localStorage.setItem("type", res.data.utype);

      router.regen();
      router.replace({ path: '/' });
    }

  } catch (error) {
    if (error.etype === 'response') {
      toast.error(error.response.data.message, 5000);
    } else {
      toast.error(error.message, 5000);
    }
  } finally {
    sending.value = false;
  }

}



</script>


<style lang="scss" scoped>
.add-btn {
  border: 0;
  background-color: transparent;

  i {
    font-size: 30px;
    color: rgba(0, 0, 0, 0.5);
  }
}

.user-term {
  color: rgba(0, 0, 0, 0.4);

  a {
    color: rgba(0, 0, 0, 0.4);

    &:hover {
      color: #6c63ff;
    }
  }
}

.header-text {
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 150%;
  color: rgba(0, 0, 0, 0.5);
}

.header-sub {
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
  color: rgba(0, 0, 0, 0.4);
}

.accordion-item,
.accordion-button {
  background-color: #eceff9;
  border: 0;
}

.accordion-button {
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  color: #6c63ff;

  &:not(.collapsed) {
    color: #6c63ff;
    background-color: #eceff9;
    box-shadow: unset;
  }

  &:focus {
    z-index: 3;
    border-color: unset;
    outline: 0;
    box-shadow: unset;
  }
}

@media (min-width: 0) and (max-width: 576px) {
  .container {
    width: 100%;
  }
}

@media (min-width: 576px) and (max-width: 1200px) {
  .container {
    width: 70%;
  }
}

@media (min-width: 1200px) {
  .container {
    width: 50%;
  }
}
</style>