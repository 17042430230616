<template>
  <div class="h-100-center">
    <div class="container my-5">
      <div class="row align-items-center">
        <div class="col-12 col-xl-5 col-lg-5 order-1 order-xl-0 order-lg-0">
          <div class="container text-center">
            <h3 class="login-header">
              Şifrenizi mi unuttunuz?
            </h3>

            <form @submit.prevent="login">
              <div class="d-grid gap-3 my-3">
                <div class="text-start">
                  <input type="email" class="form-control ps-4" :class="{ 'is-invalid': $v.email.$errors.length }"
                    placeholder="Email adresinizi yazınız" v-model="state.email" />
                  <invalid-message :errors="$v.email.$errors" />
                </div>
              </div>

              <div class="d-grid gap-4 my-4">
                <button class="btn btn-primary" type="submit" :disabled="sending || !isOnline">
                  <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true" v-if="sending"></span>
                  <span class="visually-hidden">Eposta Gönderiliyor...</span>
                  <span v-if="!sending" class="mx-2">{{ isOnline ? 'Email Gönder' : 'İnternet bağlantınız yok' }}</span>
                </button>
                <router-link to="/giris" class="btn btn-secondary">İptal</router-link>
              </div>
            </form>
          </div>
        </div>
        <div
          class="col-12 col-xl-7 col-lg-7 order-0 order-xl-1 order-lg-1 text-center px-2 px-xl-5 pt-5 pt-xl-0 pt-lg-0">
          <img class="login-img img-fluid mt-5" src="@/assets/login.svg" />
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, inject } from 'vue';
import { useVuelidate } from '@vuelidate/core';
import { required, email, helpers } from '@vuelidate/validators';
import { useConAware } from "@/utils/connectionAware";
import { useRouter } from 'vue-router';

const router = useRouter();
const { isOnline } = useConAware();
const axios = inject('axios');
const toast = inject('toast');

const sending = ref(false);
const state = ref({
  email: '',
});

const rules = {
  email: { required: helpers.withMessage('Lütfen email adresinizi yazınız.', required), email: helpers.withMessage('Lütfen geçerli bir email adresi yazınız.', email) },
};

const $v = useVuelidate(rules, state);

function focusHasErrors() {
  const elem = document.querySelector(".is-invalid");
  if (elem) {
    elem.focus();
  }
}

async function login() {
  $v.value.$touch();
  const errors = $v.value.$errors;

  if (errors.length) {
    focusHasErrors();
  } else {
    try {
      sending.value = true;
      if (isOnline.value) {
        const res = await axios.post('/api/login/forgot', {
          username: state.value.email,
          password:'-------------'
        });

        toast.info(res.data.message, 5000);
        router.replace({ path: '/' });
      }
    } catch (error) {
      if (error.etype === 'response') {
        toast.error(error.response.data.message, 5000);
      } else {
        toast.error(error.message, 5000);
      }
    } finally {
      sending.value = false;
    }
  }
}
</script>

<style lang="scss" scoped>
.forgot {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  letter-spacing: -0.05em;
  color: rgba(0, 0, 0, 0.4);
  text-decoration: none;

  &:hover {
    color: #6c63ff;
  }
}

.line-over-text {
  text-align: center;
  border-bottom: 1px solid #6c63ff;
  line-height: 0.1em;
  margin: 10px 0 20px;

  span {
    background: #eceff9;
    padding: 0 30px;
    font-weight: 400;
    font-size: 16px;
    color: #6f6c90;
  }
}

.login-header {
  font-style: normal;
  font-weight: 700;
  font-size: 30px;
  line-height: 150%;
  color: rgba(0, 0, 0, 0.7);
}

@media (min-width: 0) and (max-width: 576px) {
  .login-img {
    width: 60%;
  }

  .login-header {
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    line-height: 150%;
    color: rgba(0, 0, 0, 0.7);
  }
}

@media (min-width: 576px) and (max-width: 768px) {
  .login-img {
    width: 60%;
  }

  .login-header {
    font-style: normal;
    font-weight: 700;
    font-size: 28px;
    line-height: 150%;
    color: rgba(0, 0, 0, 0.7);
  }
}
</style>